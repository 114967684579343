import React from 'react';

import { PersonCrossIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';
import i18n from 'i18next';
import PropTypes from 'prop-types';

export const DeleteFacesCell = ({ onClick, row, buttonProps }) => (
  <Control.RoundButton
    data-testid="deleteFacesCell"
    icon={<PersonCrossIcon />}
    kind="negative"
    onClick={() => onClick(row.original)}
    title={i18n.t('lists:таблица.кнопки.удалить все лица из списка')}
    variant="dimmed"
    {...(buttonProps || {})}
  />
);

DeleteFacesCell.propTypes = ({
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  buttonProps: PropTypes.object,
  onClick: PropTypes.func.isRequired,
});

DeleteFacesCell.defaultProps = ({
  buttonProps: undefined,
});
