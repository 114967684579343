import { get, CleanSchema } from '../../../json-transform';

export const getCommonEventsFiltersQS = new CleanSchema({
  target: get('target').asOptionValue(),
  create_time__gte: get('create_time__gte.0').asISOString(),
  create_time__lt: get('create_time__lt.0').asISOString(),
  end_time_gte: get('end_time_gte').asISOString(),
  end_time_lt: get('end_time_lt').asISOString(),
  cities: get('cities').asArrayFromString(),
  areas: get('areas').asArrayFromString(),
  districts: get('districts').asArrayFromString(),
  streets: get('streets').asArrayFromString(),
  house_numbers: get('house_numbers').asArrayFromString(),
  origin_longitude: get('origin_longitude').asFloat().ifNaN(undefined),
  origin_latitude: get('origin_latitude').asFloat().ifNaN(undefined),
  longitude_delta: get('longitude_delta').asFloat().ifNaN(undefined),
  latitude_delta: get('latitude_delta').asFloat().ifNaN(undefined),
  top_similar_object_ids: get('top_similar_object_ids').asArrayFromString(),
  top_similar_external_ids: get('top_similar_external_ids').asArrayFromString(),
  age__gte: get('age.value[0]').asInt().ifNaN(undefined),
  age__lt: get('age.value[1]').asInt().ifNaN(undefined),
  gender: get('gender').asOptionValue(),
  emotions: get('emotions').asArrayOfOptionValues(),
  masks: get('masks').asArrayOfOptionValues(),
  ethnic_groups: get('ethnic_groups').asArrayOfOptionValues(),
  liveness: get('liveness').asArrayOfOptionValues(),
  deepfake: get('deepfake').asArrayOfOptionValues(),
  apparent_gender: get('apparent_gender').asArrayOfOptionValues(),
  apparent_age__gte: get('apparent_age.value[0]').asInt().ifNaN(undefined),
  apparent_age__lt: get('apparent_age.value[1]').asInt().ifNaN(undefined),
  headwear_states: get('headwear_states').asArrayOfOptionValues(),
  sleeve_lengths: get('sleeve_lengths').asArrayOfOptionValues(),
  upper_clothing_colors: get('upper_clothing_colors').asArrayOfOptionValues(),
  lower_garment_colors: get('lower_garment_colors').asArrayOfOptionValues(),
  lower_garment_types: get('lower_garment_types').asArrayOfOptionValues(),
  shoes_apparent_colors: get('shoes_apparent_colors').asArrayOfOptionValues(),
  headwear_apparent_colors: get('headwear_apparent_colors').asArrayOfOptionValues(),
  backpack_states: get('backpack_states').asArrayOfOptionValues(),
  face_ids: get('face_ids', []).ifNull([]).asArrayFromString(),
  event_ids: get('event_ids', []).ifNull([]).asArrayFromString(),
  event_id__gte: get('event_id__gte').asString(),
  event_id__lt: get('event_id__lt').asString(),
  handler_ids: get('handler_ids').asArrayOfOptionValues(),
  external_ids: get('external_ids', []).ifNull([]).asArrayFromString(),
  user_data: get('user_data').asString().ifEmptyString(undefined),
  tags: get('tags').asArrayOfOptionValues(),
  track_ids: get('track_ids').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
  order: get('order').asOptionValue(),
  page: get('page').asInt(),
  page_size: get('page_size').asInt(),
  sources: get('sources').as((sources) => {
    let mapper;
    if (typeof sources === 'string') mapper = get('sources').asString().ifEmptyString(undefined);
    if (Array.isArray(sources)) mapper = get('sources').asArrayOfOptionValues();
    if (!mapper) return sources;

    return mapper({ sources });
  }),
});

const getEventsFiltersQS = new CleanSchema({
  top_matching_candidates_label: get('top_matching_candidates_label').asString().ifEmptyString(undefined),
  top_similar_object_similarity__gte: get('top_similar_object_similarity__gte').asFloat().toNormalizedPercent().ifNaN(undefined),
  top_similar_object_similarity__lt: get('top_similar_object_similarity__lt').asFloat().toNormalizedPercent().ifNaN(undefined),
});

const getMetaFilters = ({ meta = [] }) => {
  const updatedMetaObject = {};
  meta.forEach((m) => {
    updatedMetaObject[`meta.${m.key}__${m.operator.value}:${m.type.value}`] = m.value;
  });
  return updatedMetaObject;
};

export const getEventsQS = (obj) => {
  return {
    ...getMetaFilters(obj),
    ...getEventsFiltersQS(obj),
    ...getCommonEventsFiltersQS(obj),
  };
};
