import React from 'react';

import { DEEPFAKE_STATE_OPTIONS,
  GENDER_OPTIONS,
  AGE_OPTIONS,
  TEMPERATURE_BY_GROUP_OPTIONS,
  EMOTION_OPTIONS,
  MASK_OPTIONS,
} from '@vlabs/api-bindings/src/constants';
import { LIVENESS_OPTIONS } from '@vlabs/api-bindings/src/luna-client/constants';
import { defaultFilters } from '@vlabs/pages/events/defaultFilters';
import { optionPropType } from '@vlabs/shared';
import {
  GeolocationFilterForm,
  BodyAttributesFilterForm,
  OtherFilterForm,
} from '@vlabs/shared/legacy-components/filter-forms';
import SelectOptionGroupLabel from '@vlabs/shared/legacy-components/select-option-group-label/SelectOptionGroupLabel';
import validate from '@vlabs/shared/validators';
import { Control, Divider, Fold, Group } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectHandlerOptions } from '@vlabs/pages/handlers/selectors';
import { selectSourceOptionsByAccount } from '@vlabs/pages/sources/selectors';

import st from './FilterForm.module.sass';

const FilterForm = ({
  camOptions,
  handlerOptions,
}) => {
  const { t } = useTranslation();
  const { control,
    register,
    reset,
    watch,
    formState: { errors },
    sound,
    setSoundThreshold,
    setIsSoundOn,
    setFilters,
  } = useFormContext();
  const { isSoundOn, soundThreshold } = sound;

  const similarityGte = Number(watch('top_similar_object_similarity__gte'));

  const resetForm = () => {
    reset(defaultFilters);
    setFilters({});
  };

  return (
    <div
      className={st.FilterForm}
      data-testid="filtersPanel"
    >
      <div>
        <div className={st.FilterForm__Body}>
          <Control.Switch
            id="filters-sound-notification"
            label={t('events:filter form.sound notification')}
            onChange={setIsSoundOn}
            value={isSoundOn}
          />

          <div className={st.SoundNotification__Similarity}>
            <span className={st.SoundNotification__Label}>
              {`${t('events:filter form.similarity threshold')}:`}
            </span>

            <Control.Input
              className={st.SoundNotification__Input}
              disabled={!isSoundOn}
              inputStyle="implicit"
              max={100}
              min={0}
              onChange={(event) => setSoundThreshold(event?.target?.value)}
              placeholder={t('from')}
              step="0.01"
              type="number"
              value={soundThreshold}
            />
            <span className="Subtitle-2">
              %
            </span>
          </div>
        </div>

        <Group gapSize="5px" vertical>
          <Fold
            className={st.Fold}
            compact
            isOpen
            title={t('general')}
            withPadding
          >
            <Control.Select
              control={control}
              formatGroupLabel={SelectOptionGroupLabel}
              id="sources"
              isClearable
              isCreatable
              isMulti
              label={t('source')}
              name="sources"
              options={camOptions}
            />
            <Divider small />

            <Control.Select
              control={control}
              errors={errors}
              id="handler_ids"
              isClearable
              isCreatable
              isMulti
              label={t('handler')}
              name="handler_ids"
              options={handlerOptions}
              rules={{ validate: validate.uuidOptions }}
            />
            <Divider small />

            <Control.Select
              control={control}
              formatGroupLabel={SelectOptionGroupLabel}
              id="tags"
              isClearable
              isCreatable
              isMulti
              label={t('tags')}
              name="tags"
              options={TEMPERATURE_BY_GROUP_OPTIONS.raw}
            />
          </Fold>

          <Fold
            className={st.Fold}
            compact
            title={t('labels')}
            withPadding
          >
            <Control.Input
              id="top_matching_candidates_label"
              label={t('label')}
              {...register('top_matching_candidates_label')}
            />
            <Divider small />

            <div className={st.Filter_flex}>
              <div className={st.FilterInputs_width}>
                <Control.Input
                  id="top_similar_object_similarity__gte"
                  label={t('similarity %')}
                  placeholder={t('from')}
                  {...register('top_similar_object_similarity__gte', {
                    min: validate.gte(0),
                    max: validate.lte(100),
                    validate: validate.number(),
                  })}
                  errors={errors}
                />
              </div>
              <div className={st.FilterDivider}>:</div>
              <div className={st.FilterInputs_width}>
                <Control.Input
                  placeholder={t('to')}
                  {...register('top_similar_object_similarity__lt', {
                    min: validate.gte(Math.max(similarityGte, 0)),
                    max: validate.lte(100),
                    validate: validate.number(),
                  })}
                  errors={errors}
                />
              </div>
            </div>
          </Fold>

          <Fold
            className={st.Fold}
            compact
            title={t('events:filter form.face attributes and properties')}
            withPadding
          >
            <Control.Select
              control={control}
              id="gender"
              isClearable
              label={t('gender')}
              name="gender"
              options={GENDER_OPTIONS.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="age"
              isClearable
              label={t('age')}
              name="age"
              options={AGE_OPTIONS.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="emotions"
              isClearable
              isMulti
              label={t('emotion')}
              name="emotions"
              options={EMOTION_OPTIONS.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="masks"
              isClearable
              isMulti
              label={t('mask')}
              name="masks"
              options={MASK_OPTIONS.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="liveness"
              isMulti
              label={t('Liveness')}
              name="liveness"
              options={LIVENESS_OPTIONS.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="deepfake"
              isMulti
              label={t('deepfake')}
              name="deepfake"
              options={DEEPFAKE_STATE_OPTIONS.raw}
            />
          </Fold>

          <Fold
            className={st.Fold}
            compact
            title={t('events:filter form.body attributes and properties')}
            withPadding
          >
            <BodyAttributesFilterForm />
          </Fold>

          <Fold
            className={st.Fold}
            compact
            title={t('location')}
            withPadding
          >
            <GeolocationFilterForm />
          </Fold>

          <Fold
            className={st.Fold}
            compact
            title={t('другое')}
            withPadding
          >
            <OtherFilterForm />
          </Fold>
        </Group>
      </div>
      <div className="StickyBottomContainer">
        <div className={st.ButtonPanel}>
          <Control.Button type="submit">
            {t('button.filter')}
          </Control.Button>

          <Control.Button kind="attention" onClick={resetForm}>
            {t('button.reset')}
          </Control.Button>
        </div>
      </div>
    </div>
  );
};

FilterForm.propTypes = {
  camOptions: PropTypes.arrayOf(optionPropType),
  handlerOptions: PropTypes.arrayOf(optionPropType),
};

FilterForm.defaultProps = {
  camOptions: [],
  handlerOptions: [],
};

export default connect(
  (state) => ({
    camOptions: selectSourceOptionsByAccount(state),
    handlerOptions: selectHandlerOptions(state),
  }),
)(FilterForm);
