import React, { useContext } from 'react';

import {
  GeolocationFilterForm,
  CommonEventFilterForm,
  FaceAttributesFilterForm,
  BodyAttributesFilterForm,
  OtherFilterForm,
} from '@vlabs/shared/legacy-components/filter-forms';
import validate from '@vlabs/shared/validators';
import { Fold, Control, Divider, Group } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SearchContext } from '../search-context';
import st from './shared.module.sass';

const EventFiltersForm = () => {
  const { t } = useTranslation();
  const { register, formState: { errors } } = useFormContext();

  const { handlerOptions, camOptions } = useContext(SearchContext);

  return (
    <Group gapSize={0} vertical>
      <Fold
        className={st.Fold}
        compact
        isOpen
        title={t('general')}
        withPadding
      >
        <CommonEventFilterForm
          camOptions={camOptions}
          handlerOptions={handlerOptions}
        />

        <Divider small />

        <Control.Input
          id="threshold"
          label={t('search:фильтры.схожесть')}
          {...register('threshold', {
            min: validate.gte(0),
            max: validate.lte(100),
            validate: validate.number(),
          })}
          errors={errors}
          placeholder={t('не задано')}
        />
      </Fold>

      <Fold
        className={st.Fold}
        compact
        title={t('labels')}
        withPadding
      >
        <Control.Input
          id="top_matching_candidates_label"
          label={t('label')}
          {...register('top_matching_candidates_label', { maxLength: validate.maxLength(36) })}
          errors={errors}
        />

        <Divider small />

        <Control.Input
          id="top_similar_object_ids"
          label={t('id объектов с максимальным результатом сравнения')}
          {...register('top_similar_object_ids', { validate: validate.multipleUuid() })}
          errors={errors}
        />
      </Fold>

      <Fold
        className={st.Fold}
        compact
        title={t('events:filter form.face attributes and properties')}
        withPadding
      >
        <FaceAttributesFilterForm />
      </Fold>

      <Fold
        className={st.Fold}
        compact
        title={t('events:filter form.body attributes and properties')}
        withPadding
      >
        <BodyAttributesFilterForm />
      </Fold>

      <Fold
        className={st.Fold}
        compact
        title={t('location')}
        withPadding
      >
        <GeolocationFilterForm />
      </Fold>

      <Fold
        className={st.Fold}
        compact
        title={t('другое')}
        withPadding
      >
        <OtherFilterForm />
      </Fold>
    </Group>
  );
};

export { EventFiltersForm };
