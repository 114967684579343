import React, { useState } from 'react';

import { Pages } from '@vlabs/uikit';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchCurrentUser, login as auth } from './store';

export function LoginPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(null);

  const loginHandler = async ({ login, password }) => {
    try {
      await auth({ email: login, password });
      dispatch(fetchCurrentUser);
      history.push('/');
    } catch (e) {
      if (e.response) {
        const { status, data } = e.response;
        if (status === 401) {
          setError(t('auth:логин или пароль неверны'));
        } else if (status === 403) {
          const { timeout } = data;
          const timeoutFmt = Math.ceil((timeout / 60) * 100) / 100;
          toast.error(i18next.t('auth:ваша учётная запись заблокирована', { count: timeoutFmt }));
        }
      }
    }
  };

  return <Pages.LoginPage error={error} onSubmit={loginHandler} useEmailAsLogin />;
}
