/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { optionPropType } from '@vlabs/shared';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import PropTypes from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';

import { selectHandlerOptions } from '@vlabs/pages/handlers/selectors';
import { selectSourceOptionsByAccount } from '@vlabs/pages/sources/selectors';

import { SearchContext } from './search-context';
import SearchPage from './SearchPage';
import {
  selectMatcherResults,
  selectReferencesPreviewPage,
  selectCandidateOrigin,
  selectReferenceType,
  selectReferences,
  selectSearchState,
} from './selectors';
import * as searchStore from './store';

function SearchContainer({
  setMatcherType,
  matcherType,
  matchResults,
  candidateOrigin,
  setFilters,
  setReferenceType,
  referenceType,
  references,
  referencesPreview,
  setPreviewPageIndex,
  setReferences,
  setCandidateOrigin,
  resetSearchState,
  resetMatchResults,
  handlerOptions,
  camOptions,
  listOptions,
}) {
  const [detections, setDetections] = useState();
  const [selectedDetections, setSelectedDetections] = useState();
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState();
  const [file, setFile] = useState();
  const [queryParams, setQueryParams] = useState([]);
  const [inputType, setInputType] = useState('image');

  useEffect(() => {
    setQueryParams(qs.parse(window.location.search, { ignoreQueryPrefix: true }));
  }, [window.location.search]);

  useEffect(() => {
    if (!Object.keys(queryParams).length) return;

    const reference = queryParams?.references?.[0];
    if (!reference) return;

    setInputType(reference.type);
    setInputValue(reference.id);
    setReferenceType(reference.referenceType);
    if (reference?.candidateOrigin) setCandidateOrigin(reference.candidateOrigin);
    setReferences([{ id: reference.id, type: reference.referenceType }]);
  }, [queryParams]);

  const resetSearchInfo = useCallback(() => {
    resetSearchState();
    setDetections(undefined);
    setSelectedDetections(undefined);
    setFile(undefined);
    setInputError(undefined);
  }, [resetSearchState, setFile, setReferences, setInputError]);

  useEffect(() => {
    return () => {
      resetSearchInfo();
      setCandidateOrigin('events');
      setReferenceType('sdk_descriptor');
    };
  }, []);

  const onMatcherTypeChange = (type) => {
    if (type === 'body') setCandidateOrigin('events');
    setMatcherType(type);
    resetMatchResults();
  };

  const context = useMemo(() => ({
    setMatcherType,
    matcherType,
    onMatcherTypeChange,
    inputType,
    setInputType,
    setFile,
    file,
    setDetections,
    detections,
    setInputError,
    inputError,
    setInputValue,
    inputValue,
    setReferenceType,
    setReferences,
    setSelectedDetections,
    selectedDetections,
    resetSearchInfo,
    resetSearchState,
    matchResults,
    candidateOrigin,
    setCandidateOrigin,
    setFilters,
    resetMatchResults,
    referenceType,
    references,
    referencesPreview,
    handlerOptions,
    listOptions,
    camOptions,
    setPreviewPageIndex,
  }), [setMatcherType,
    matcherType,
    onMatcherTypeChange,
    inputType,
    setInputType,
    setFile,
    file,
    setDetections,
    detections,
    setInputError,
    inputError,
    setInputValue,
    inputValue,
    setReferenceType,
    setReferences,
    setSelectedDetections,
    selectedDetections,
    resetSearchInfo,
    resetSearchState,
    matchResults,
    candidateOrigin,
    setCandidateOrigin,
    setFilters,
    resetMatchResults,
    referenceType,
    references,
    referencesPreview,
    handlerOptions,
    listOptions,
    camOptions,
    setPreviewPageIndex]);

  return (
    <SearchContext.Provider value={context}>
      <SearchPage />
    </SearchContext.Provider>
  );
}

SearchContainer.propTypes = {
  matcherType: PropTypes.string.isRequired,
  matchResults: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.shape({
      faces: PropTypes.arrayOf(PropTypes.any),
      events: PropTypes.arrayOf(PropTypes.any),
    }),
  ]),
  references: PropTypes.arrayOf(PropTypes.shape({})),
  referencesPreview: PropTypes.arrayOf(PropTypes.shape({})),
  handlerOptions: PropTypes.arrayOf(optionPropType),
  listOptions: PropTypes.arrayOf(optionPropType),
  camOptions: PropTypes.arrayOf(optionPropType),
  candidateOrigin: PropTypes.string,
  resetSearchState: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  setReferenceType: PropTypes.func.isRequired,
  setReferences: PropTypes.func.isRequired,
  setCandidateOrigin: PropTypes.func.isRequired,
  resetMatchResults: PropTypes.func.isRequired,
  setMatcherType: PropTypes.func.isRequired,
  setPreviewPageIndex: PropTypes.func.isRequired,
  referenceType: PropTypes.string,
};

SearchContainer.defaultProps = {
  candidateOrigin: PropTypes.oneOf(['events', 'faces']),
  referenceType: PropTypes.oneOf(['sdk_descriptor', 'events', 'face', 'face_external_id']),
  matchResults: undefined,
  handlerOptions: [],
  listOptions: [],
  camOptions: [],
  references: [],
  referencesPreview: [],
};

export default connect(
  (state) => ({
    matchResults: selectMatcherResults(state),
    matcherType: selectSearchState(state).matcherType,
    candidateOrigin: selectCandidateOrigin(state),
    referenceType: selectReferenceType(state),
    references: selectReferences(state),
    referencesPreview: selectReferencesPreviewPage(state),
    handlerOptions: selectHandlerOptions(state),
    listOptions: selectListOptions(state),
    camOptions: selectSourceOptionsByAccount(state),
  }),
  (dispatch) => ({
    setFilters: (candidates) => dispatch(searchStore.setFilters(candidates)),
    setReferences: (references) => dispatch(searchStore.setReferences(references)),
    setReferenceType: (referenceType) => dispatch(searchStore.setReferenceType({ referenceType })),
    setMatcherType: (matcherType) => dispatch(searchStore.setMatcherType({ matcherType })),
    setCandidateOrigin: (type) => dispatch(searchStore.setCandidateOrigin(type)),
    setPreviewPageIndex: (pageIndex) => dispatch(searchStore.setPageIndex({ pageIndex })),
    resetSearchState: () => dispatch(searchStore.resetSearchState()),
    resetMatchResults: () => dispatch(searchStore.resetMatchResults()),
  }),
)(SearchContainer);
