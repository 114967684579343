/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo, useState } from 'react';

import { FaceEditForm } from '@vlabs/pages/face-details-page/components/FaceEditForm';
import { Sample } from '@vlabs/shared/components/sample/Sample';
import { GoToCell } from '@vlabs/shared/components/table';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { selectListById } from '@vlabs/shared/selectors/listSelectors';
import { actionColumnProps } from '@vlabs/shared/utils';
import { CreateFaceWidget } from '@vlabs/shared/widgets/faces';
import { ExporterTaskWidget } from '@vlabs/shared/widgets/tasks';
import {
  Modal, TableCells, TableFilters, Table,
  openConfirmPopup, Panel, Divider, Page, Control,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useSelector, shallowEqual } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';

import { viewerCan } from '@vlabs/pages/auth/selectors';
import * as store from '@vlabs/pages/lists/face-list-page/store';

import { DetachCell } from '../components/DetachCell';
import { selectFaces } from './selectors';
import './FaceListPage.sass';

function FaceListPage({
  facesData,
  setFilters,
  setPageSize,
  setPageIndex,
  setListId,
  deleteFaces,
  detachFaces,
  fetchFaces,
  updateFace,
  can,
}) {
  const { listId } = useParams();
  const { t } = useTranslation();
  const [editFaceModalIsOpen, setEditFaceModalIsOpen] = useState(false);
  const [currentFace, setCurrentFace] = useState(undefined);

  const list = useSelector(selectListById(listId), shallowEqual);
  const listOption = { value: listId, label: list?.user_data || list?.list_id };

  const onDetachHandler = (data) => {
    const faceIds = Array.isArray(data) ? data.map((l) => l.list_id) : [data.list_id];

    const message = t('faces:подтверждение.текст', {
      context: 'открепление',
      count: faceIds.length,
      item: Array.isArray(data) ? data[0].face_id : data.face_id,
    });

    const onConfirm = () => {
      detachFaces(listId, data);
    };

    openConfirmPopup({
      title: t('faces:подтверждение.заголовок', { context: 'открепление' }),
      message,
      type: 'warning',
      onConfirm,
      cancelLabel: t('faces:подтверждение.отмена', { context: 'открепление' }),
      confirmLabel: t('faces:подтверждение.submit', { context: 'открепление' }),
    });
  };

  const cols = useMemo(() => ([
    actionColumnProps({
      id: 'selection',
      Header: TableCells.SelectAllRowsCell,
      Cell: TableCells.SelectRowCell,
    }),
    {
      id: 'thumb',
      accessor: 'uiAvatarURL',
      Cell: ({ value }) => <Sample className="FaceListPage__Sample" image={value} />,
      width: 100,
    },
    {
      Header: t('faces:table.columns.user_data'),
      accessor: 'user_data',
      Filter: TableFilters.buildTextFilter(),
    },
    {
      Header: t('faces:table.columns.external_id'),
      accessor: 'external_id',
      Filter: TableFilters.buildTextFilter(),
    },
    {
      Header: t('faces:table.columns.create_time'),
      accessor: 'create_time',
      Cell: TableCells.DateCell,
      Filter: TableFilters.buildDateFilter(),
    },
    actionColumnProps({ id: 'action-edit', Cell: ({ row }) => TableCells.EditCell({ row, buttonProps: { hasPermission: can(permissions.face.modification) } }) }),
    actionColumnProps({ id: 'action-detach', Cell: ({ row }) => DetachCell({ row, onClick: onDetachHandler, buttonProps: { hasPermission: can(permissions.list.modification) } }) }),
    actionColumnProps({ id: 'action-delete', Cell: ({ row }) => TableCells.DeleteCell({ row, buttonProps: { hasPermission: can(permissions.face.deletion) } }) }),
    actionColumnProps({
      id: 'action-go-to-face',
      accessor: ({ face_id }) => generatePath(routes.facePage, { faceId: face_id }),
      Cell: ({ value }) => GoToCell({ value, buttonProps: { hasPermission: can(permissions.face.view) } }),
      width: 50,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ]), [t]);

  useEffect(() => {
    setListId(listId);
    return () => { setListId(undefined); };
  }, [listId, setListId]);

  const afterCreateFace = () => fetchFaces();

  const onEditFace = async (data) => {
    await updateFace(currentFace, data);
    setEditFaceModalIsOpen(false);
    setCurrentFace(undefined);
  };

  const onDeleteHandler = (data) => {
    const faceIds = Array.isArray(data) ? data.map((l) => l.list_id) : [data.list_id];

    const message = t('faces:подтверждение.текст', {
      context: 'удаление',
      count: faceIds.length,
      item: Array.isArray(data) ? data[0].face_id : data.face_id,
    });

    const onConfirm = () => {
      deleteFaces(data);
    };

    openConfirmPopup({
      title: t('faces:подтверждение.заголовок', { context: 'удаление' }),
      message,
      type: 'delete',
      onConfirm,
    });
  };
  const pageTitle = t('faces:заголовок', { title: list?.user_data, facesCount: facesData.meta.count });

  const onOpenEditFaceForm = (face) => {
    setCurrentFace(face);
    setEditFaceModalIsOpen(true);
  };
  const closeEditFaceForm = () => {
    setEditFaceModalIsOpen(false);
    setCurrentFace(undefined);
  };

  const extraButtons = (context) => {
    const { selectedFlatRows } = context;
    const selectedRows = selectedFlatRows.map(({ original }) => original);

    return (
      <>
        <CreateFaceWidget afterSubmit={afterCreateFace} defaultValues={{ lists: [listOption] }} />
        {/* Для отображения в форме передаем в Select сформированный опшин */}
        <ExporterTaskWidget filters={{ list_id: listOption }} objectType="faces" />
        <Control.Button
          data-testid="table.detachFace"
          disabled={selectedRows.length === 0}
          hasPermission={can(permissions.list.modification)}
          kind="attention"
          onClick={() => onDetachHandler(selectedRows)}
        >
          {t('faces:кнопки.открепление')}
        </Control.Button>
      </>
    );
  };

  return (
    <Page
      className="Global__Table_fullScreen_wrapper"
      title={pageTitle}
    >
      <Modal
        isOpen={editFaceModalIsOpen}
        onRequestClose={closeEditFaceForm}
      >
        <FaceEditForm
          data-testid="faceEditForm"
          defaultValues={currentFace}
          onSubmit={onEditFace}
        />
      </Modal>

      <Divider small />
      <Panel className="FaceListPage__Table_fullScreen">
        <Table
          columns={cols}
          data={facesData.data}
          disableSortBy={false}
          extraButtons={extraButtons}
          filters={facesData.filters}
          meta={{ count: facesData.meta.count }}
          onActions={{
            onEditRow: { handler: onOpenEditFaceForm, can: can(permissions.face.modification) },
            onDeleteRow: { handler: onDeleteHandler, can: can(permissions.face.deletion) },
          }}
          pageIndex={facesData.pageIndex}
          pageSize={facesData.pageSize}
          pageSizeOptions={facesData.pageSizeOptions}
          paginationType="offsetBased"
          rowProps={({ original }) => ({ 'data-face-id': original.face_id })}
          setFilters={setFilters}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />
      </Panel>
    </Page>
  );
}

FaceListPage.propTypes = {
  facesData: PropTypes.objectOf(PropTypes.any),
  setFilters: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
  setListId: PropTypes.func.isRequired,
  deleteFaces: PropTypes.func.isRequired,
  detachFaces: PropTypes.func.isRequired,
  fetchFaces: PropTypes.func.isRequired,
  updateFace: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
};

FaceListPage.defaultProps = {
  facesData: {},
};

export default connect(
  (state) => ({
    facesData: selectFaces(state),
    can: viewerCan(state),
  }),
  (dispatch) => ({
    fetchFaces: () => dispatch(store.fetchFaces),
    deleteFaces: (id) => dispatch(store.deleteFaces(id)),
    detachFaces: (listId, params) => dispatch(store.detachFaces(listId, params)),
    updateFace: (faceId, params) => dispatch(store.updateFace(faceId, params)),
    setListId: (listId) => dispatch(store.updateListId(listId)),
    setFilters: (filters) => dispatch(store.updateFilters({ filters })),
    setPageSize: (pageSize) => dispatch(store.updatePageSize({ pageSize })),
    setPageIndex: (pageIndex) => dispatch(store.updatePage({ pageIndex })),
  }),
)(FaceListPage);
