import React from 'react';

import { DownloadIcon, VideoIcon, CalendarIcon, SlidersIcon } from '@vlabs/icons';
import DownloadButton from '@vlabs/shared/legacy-components/download-button/DownloadButton';
import HandlerLink from '@vlabs/shared/legacy-components/handler-link/HandlerLink';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import StreamLink from '@vlabs/shared/legacy-components/stream-link/StreamLink';
import { parseISOToLocale, downloadJson } from '@vlabs/shared/utils';
import { openConfirmPopup } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './EventInfoCell.module.sass';

const EventInfoCell = ({
  original,
  className,
}) => {
  const { t } = useTranslation();

  const onDownloadHandler = ({ event_id, meta }) => {
    const onConfirm = () => {
      downloadJson(`${event_id}_meta`, meta);
    };

    openConfirmPopup({
      icon: <DownloadIcon />,
      title: t('events:подтверждение.загрузить мета.заголовок'),
      message: t('events:подтверждение.загрузить мета.текст'),
      onConfirm,
      confirmButtonProps: {
        kind: 'success',
      },
      confirmLabel: t('events:подтверждение.submit', { context: 'скачать' }),
    });
  };

  return (
    <div className={cn(st.RowInfoWidth, className)}>
      {original.create_time && (
        <InfoRow
          columnDirection
          data-testid="eventInfoCell.createTime"
          icon={<CalendarIcon className="StaticIcon" />}
          title={t('date created')}
          value={parseISOToLocale(original.create_time)}
        />
      )}
      {original.source && (
        <InfoRow
          columnDirection
          data-testid="eventInfoCell.source"
          icon={<VideoIcon className="StaticIcon" />}
          title={t('source')}
          value={<StreamLink source={original.source} />}
        />
      )}
      {original.handler_id && (
        <InfoRow
          columnDirection
          data-testid="eventInfoCell.handlers"
          icon={<SlidersIcon className="StaticIcon" />}
          title={t('handler')}
          value={<HandlerLink handlerId={original.handler_id} />}
        />
      )}
      {original.meta && (
        <div className={cn(st.Meta)}>
          {t('метаданные')}
          {DownloadButton({ onClick: () => onDownloadHandler(original) })}
        </div>
      )}
    </div>
  );
};

EventInfoCell.propTypes = {
  original: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
};
EventInfoCell.defaultProps = {
  original: undefined,
  className: undefined,
};

export { EventInfoCell };
