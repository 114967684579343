/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Tooltip } from '../tooltip/Tooltip';

import './SettingsItemWrapper.sass';

const SettingsItemWrapper = ({
  onClick,
  node,
  title,
  children,
  'data-tip': dataTip,
  disabled,
  className,
  titleClassName,
  contentClassName,
  ...restProps
}) => {
  return (
    <div
      className={cn(
        'SettingsItemWrapper',
        className,
      )}
      onClick={onClick}
      {...restProps}
    >
      <div
        className={cn(
          titleClassName,
          'Subtitle-1',
          {
            SettingsItemWrapper__Title: true,
            SettingsItemWrapper__Title_disabled: disabled,
          },
        )}
      >
        {node && <div>{node}</div>}

        {
        typeof title === 'string'
          ? <div>{title}</div>
          : title
      }

        {dataTip && (
          <Tooltip
            className="SettingsItemWrapper__Tooltip"
            data-tooltip-content={dataTip}
            tooltipProps={{ place: 'right' }}
          >
            <CircleInfoIcon className="SettingsItemWrapper__Tooltip_icon" />
          </Tooltip>
        )}
      </div>

      <div className={cn(contentClassName)}>
        {children}
      </div>
    </div>
  );
};

SettingsItemWrapper.propTypes = {
  onClick: PropTypes.func,
  node: PropTypes.node,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node,
  'data-tip': PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
};

SettingsItemWrapper.defaultProps = {
  onClick: undefined,
  node: undefined,
  title: undefined,
  children: undefined,
  'data-tip': undefined,
  disabled: false,
  className: undefined,
  titleClassName: undefined,
  contentClassName: undefined,
};

export { SettingsItemWrapper };
