import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import FileSaver from 'file-saver';
import i18n from 'i18next';
import { toast } from 'react-toastify';

const initialState = {
  state: undefined,
  page: {
    data: [],
    meta: {},
    pageIndex: 0,
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100],
  },
  data: undefined,
  // FIXME пересмотреть работу с данными, уйти от использования редакса
  verificationResults: undefined,
  rawVerificationResults: undefined,
};

const store = createSlice({
  name: 'verifiers',
  initialState,
  reducers: {
    setPageData(state, { payload: { data, meta } }) {
      state.page.data = data;
      state.page.meta = meta;
      state.state = 'loaded';
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.page.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } = {} }) {
      if (pageSize !== undefined) state.page.pageSize = pageSize;
    },
    setVerifier(state, { payload }) {
      state.data = payload;
    },
    resetVerifier(state) {
      state.data = undefined;
    },
    setVerificationResults(state, { payload: { data, rawData } }) {
      state.verificationResults = data;
      state.rawVerificationResults = rawData;
    },
    resetVerificationResults(state) {
      state.verificationResults = undefined;
      state.rawVerificationResults = undefined;
    },
  },
});

export default store.reducer;

export const {
  setPageSize, setPage, setVerifier,
  setPageData, setVerificationResults, resetVerificationResults,
} = store.actions;

export const fetchVerifiersList = async (dispatch, getState) => {
  const { verifiers: {
    page: { pageSize, pageIndex },
  } } = getState();

  const data = await apiContainer.lunaClient.verifiers.getPage({ page_size: pageSize, page: pageIndex + 1 });
  dispatch(setPageData(data));
};

export const fetchVerifier = (verifierId) => async (dispatch) => {
  dispatch(store.actions.resetVerifier());
  dispatch(store.actions.resetVerificationResults());

  try {
    const response = await apiContainer.lunaClient.verifiers.get(verifierId);
    dispatch(setVerifier(response));
  } catch (error) {
    dispatch(setVerifier(undefined));
    throw error;
  }
};

export const updateVerifier = (verifierId, values) => async (dispatch) => {
  await apiContainer.lunaClient.verifiers.update(verifierId, values);
  toast.success(`${i18n.t('verifiers:api.успешно обновлен')}.`);
  dispatch(fetchVerifiersList);
};

export const getVerificationResults = (verifierName) => (_, getState) => {
  const { verifiers: { rawVerificationResults } } = getState();
  const blob = new Blob([JSON.stringify(rawVerificationResults, null, 2)], { type: 'application/json' });
  FileSaver.saveAs(blob, `${verifierName}.json`);
};

export const createVerifier = (values) => async (dispatch) => {
  const { verifier_id: verifierId } = await apiContainer.lunaClient.verifiers.create(values);
  dispatch(fetchVerifiersList);
  return verifierId;
};

export const verify = (verifierId, params) => async (dispatch) => {
  dispatch(resetVerificationResults());

  const response = await apiContainer.lunaClient.verifiers.verify(verifierId, params);
  const isVerifyResultExist = response.data.some(({ status }) => status !== undefined);

  if (!isVerifyResultExist) {
    toast.warning(i18n.t('verifiers:api.предупреждение.нет результатов верификации'));
    return;
  }

  dispatch(setVerificationResults(response));
};

export const deleteVerifier = (id) => async (dispatch) => {
  await apiContainer.lunaClient.verifiers.delete(id);
  toast.success(`${i18n.t('verifiers:api.успешно удален')}.`);
  dispatch(fetchVerifiersList);
};

export const updatePageSize = (params) => async (dispatch) => {
  dispatch(setPageSize(params));
  dispatch(fetchVerifiersList);
};

export const updatePage = (params) => async (dispatch) => {
  dispatch(setPage(params));
  dispatch(fetchVerifiersList);
};
