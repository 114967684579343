import React, { useCallback, useMemo, useState } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { RefreshRightIcon } from '@vlabs/icons';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { actionColumnProps } from '@vlabs/shared/utils';
import { ExporterTaskWidget } from '@vlabs/shared/widgets/tasks';
import { GarbageCollectionStepperForm } from '@vlabs/shared/widgets/tasks/garbage-collection/GarbageCollectionStepperForm';
import { PackageImportWidget } from '@vlabs/shared/widgets/tasks/package-import/PackageImportWidget';
import {
  useModal, TableCells, useTableHook, Table, openConfirmPopup, Panel, Page, Control,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { DeleteAndDetachCell } from './components/DeleteAndDetachCell';
import { DeleteFacesCell } from './components/DeleteFacesCell';
import ListCreateForm from './forms/ListCreateForm';
import * as storeActions from './store';

const RefreshFaceCountCell = (({ value: { list_id, updateFaceCount } }) => {
  const { t } = useTranslation();

  const handleClick = (e) => {
    e.stopPropagation();
    updateFaceCount(list_id);
  };

  return (
    <Control.RoundButton
      data-testid="faceCountButton"
      icon={<RefreshRightIcon />}
      kind="primary"
      onClick={(e) => handleClick(e)}
      title={t('lists:таблица.кнопки.узнать количество лиц')}
      variant="dimmed"
    />
  );
});
function ListsListPage({
  deleteList,
  deleteLists,
  createList,
  updateList,
  deleteListsWithoutFaces,
  deleteListWithoutFaces,
  can,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const gcModal = useModal();
  const listModal = useModal();

  const [selectedList, setSelectedList] = useState(undefined);
  const [faceCount, setFaceCount] = useState({});

  const onFetch = (pageSize, params) => storeActions.fetchListsList(pageSize, params);
  const tableProps = useTableHook(useCallback(onFetch, []), { paginationType: 'offsetBased' });

  const onShowForm = (list) => {
    if (list) setSelectedList(list);
    listModal.open();
  };

  const onFormSubmit = async (list) => {
    if (list?.list_id) {
      await updateList(list);
    } else {
      await createList(list);
    }
    listModal.close();
    setSelectedList(undefined);
    tableProps.onFetch();
  };

  const onDeleteLists = useCallback(({ data, withFace }) => {
    const text = withFace ? 'удалить с лицами' : 'удалить без лиц';
    const deleteMany = withFace ? deleteLists : deleteListsWithoutFaces;
    const deleteOne = withFace ? deleteList : deleteListWithoutFaces;

    const listIds = Array.isArray(data) ? data.map((list) => list.list_id) : [data.list_id];
    const message = t(`lists:подтверждение.${text}.текст`, {
      count: listIds.length,
      item: Array.isArray(data) ? data[0].user_data : data.user_data,
    });

    const onConfirm = async () => {
      if (Array.isArray(data)) {
        await deleteMany(listIds);
      } else if (typeof data === 'object' || data instanceof Object) {
        await deleteOne(listIds);
      }
      tableProps.onFetch();
    };

    openConfirmPopup({
      title: t(`lists:подтверждение.${text}.заголовок`),
      message,
      type: 'delete',
      onConfirm,
    });
  }, [deleteList, deleteListWithoutFaces, deleteLists, deleteListsWithoutFaces, t, tableProps]);

  const deleteWithFace = (data) => onDeleteLists({ data, withFace: true });
  const deleteWithoutFace = useCallback((data) => onDeleteLists({ data, withFace: false }), [onDeleteLists]);
  const onSelectHandler = ({ list_id }) => history.push(generatePath(routes.lists.read, { listId: list_id }));

  const updateFaceCount = useCallback(async (listId) => {
    const response = await storeActions.getFaceCountInList(listId);
    setFaceCount((prevState) => ({ ...prevState, [listId]: response.data.faces_count }));
  }, []);

  const extraButtons = (context) => {
    const {
      selectedFlatRows,
    } = context;
    const selectedRows = selectedFlatRows.map(({ original }) => original);
    return (
      <>
        <ExporterTaskWidget objectType="faces" />
        <PackageImportWidget />
        <Control.Button
          data-testid="table.deleteListWithoutFaces"
          disabled={selectedRows.length === 0}
          kind="negative"
          onClick={() => deleteWithoutFace(selectedRows)}
        >
          {t('lists:таблица.кнопки.удалить без лиц')}
        </Control.Button>
      </>
    );
  };

  const onDeleteAllFacesInListFormSubmit = (params) => {
    openConfirmPopup({
      title: t('tasks:GCTask.подтверждение.заголовок'),
      message: t('tasks:GCTask.подтверждение.сообщение'),
      type: 'delete',
      onConfirm: async () => {
        await apiContainer.lunaClient.tasks.createGCTask(params);
        toast.info(t('tasks:api.GCTask.создание.успех'));
        gcModal.close();
      },
      confirmLabel: t('кнопка.ок'),
    });
  };

  const deleteAllFacesInList = useCallback((v) => {
    setSelectedList(v);
    gcModal.open();
  }, [gcModal]);

  const columns = useMemo(() => {
    const $columns = [
      actionColumnProps({
        id: 'selection',
        type: 'action',
        Header: TableCells.SelectAllRowsCell,
        Cell: TableCells.SelectRowCell,
      }),
      { Header: t('название'), accessor: 'user_data' },
      { Header: t('date created'), accessor: 'create_time', Cell: TableCells.DateCell },
      { Header: t('дата изменения'), accessor: 'last_update_time', Cell: TableCells.DateCell },
      actionColumnProps({ id: 'action-refresh', Cell: RefreshFaceCountCell, accessor: ({ list_id }) => ({ list_id, updateFaceCount }) }),
      { Header: t('lists:количество лиц'), accessor: 'count', width: 80, align: 'center' },
      actionColumnProps({ id: 'edit', Cell: ({ row }) => TableCells.EditCell({ row, buttonProps: { hasPermission: can(permissions.list.modification) } }) }),
      actionColumnProps({ id: 'delete lists without faces', Cell: ({ row }) => DeleteAndDetachCell({ onClick: deleteWithoutFace, row, buttonProps: { hasPermission: can(permissions.list.deletion) } }) }),
      actionColumnProps({ id: 'delete all faces in list', Cell: ({ row }) => DeleteFacesCell({ onClick: deleteAllFacesInList, row, buttonProps: { hasPermission: can(permissions.task.creation) } }) }),
      actionColumnProps({ id: 'delete lists with faces', Cell: ({ row }) => TableCells.DeleteCell({ row, buttonProps: { hasPermission: can(permissions.list.deletion) } }) }),
    ];

    return $columns;
  }, [deleteAllFacesInList, deleteWithoutFace, t, updateFaceCount]);

  const tableData = useMemo(() => {
    return {
      ...tableProps,
      data: tableProps?.data?.map((list) => ({
        ...list,
        count: faceCount[list.list_id],
      })),
    };
  }, [tableProps, faceCount]);

  return (
    <Page className="Global__Table_fullScreen_wrapper">
      {gcModal.wrap(<GarbageCollectionStepperForm
        filters={{ list_id: selectedList?.list_id }}
        onSubmit={onDeleteAllFacesInListFormSubmit}
      />)}
      {listModal.wrap(<ListCreateForm
        defaultValues={selectedList}
        onSubmit={onFormSubmit}
      />)}
      <Panel className="Global__Table_fullScreen">
        <Table
          {...tableData}
          columns={columns}
          disableSortBy={false}
          extraButtons={extraButtons}
          onActions={{
            onClickRow: { handler: onSelectHandler, can: can(permissions.list.view) },
            onAddRow: { handler: onShowForm, can: can(permissions.list.creation) },
            onEditRow: { handler: onShowForm },
            onDeleteRow: { label: t('lists:таблица.кнопки.удалить с лицами'), handler: deleteWithFace, can: can(permissions.list.deletion) },
          }}
          // eslint-disable-next-line react/prop-types
          rowProps={(row) => ({ 'data-list-id': row.original.list_id })}
        />
      </Panel>
    </Page>
  );
}

ListsListPage.propTypes = {
  deleteList: PropTypes.func.isRequired,
  deleteLists: PropTypes.func.isRequired,
  createList: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
  deleteListsWithoutFaces: PropTypes.func.isRequired,
  deleteListWithoutFaces: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
};

export default connect((state) => ({
  can: viewerCan(state),
}),
(dispatch) => ({
  deleteList: (listIds) => dispatch(storeActions.deleteList(listIds)),
  deleteLists: (listIds) => dispatch(storeActions.deleteLists(listIds)),
  createList: (params) => dispatch(storeActions.createList(params)),
  updateList: (params) => dispatch(storeActions.updateList(params)),
  deleteListsWithoutFaces: (listIds) => dispatch(storeActions.deleteListsWithoutFaces(listIds)),
  deleteListWithoutFaces: (listIds) => dispatch(storeActions.deleteListWithoutFaces(listIds)),
}))(ListsListPage);
