import React from 'react';

import { AgeCard } from '@vlabs/shared/legacy-components/attribute-card/AgeCard';
import { EmotionCard } from '@vlabs/shared/legacy-components/attribute-card/EmotionCard';
import { GenderCard } from '@vlabs/shared/legacy-components/attribute-card/GenderCard';
import { MaskCard } from '@vlabs/shared/legacy-components/attribute-card/MaskCard';
import PropTypes from 'prop-types';

import st from './tabs.module.sass';

export function AttributesTab({
  age,
  gender,
  emotion,
  mask,
}) {
  return (
    <div className={st.TabPanel}>
      {gender !== undefined && <GenderCard gender={gender} />}
      {age !== undefined && <AgeCard age={age} />}
      {typeof mask === 'number' && <MaskCard mask={mask} />}
      {typeof emotion === 'number' && <EmotionCard emotion={emotion} />}
    </div>
  );
}

AttributesTab.propTypes = {
  age: PropTypes.number,
  gender: PropTypes.number,
  emotion: PropTypes.number,
  mask: PropTypes.number,
};
AttributesTab.defaultProps = {
  age: undefined,
  gender: undefined,
  emotion: undefined,
  mask: undefined,
};
