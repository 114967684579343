import React from 'react';

import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { Control } from '@vlabs/uikit';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { viewerCan } from '@vlabs/pages/auth/selectors';
import { selectStreamOptions } from '@vlabs/pages/sources/luna-streams/features/streams/selectors';

import st from './StreamLink.module.sass';

const StreamLink = ({
  source,
  availableStreams,
  can,
}) => {
  const stream = availableStreams?.find(({ value }) => value === source);

  if (!stream) {
    return source || i18next.t('удалён');
  }

  return (
    <div className={st.CamLinks}>
      <Control.Link
        data-testid={`CamLink${stream.streamId}`}
        disabled={!can(permissions.source.view)}
        target="_blank"
        to={generatePath(routes.sources.lunaStreams.streams.view, { streamId: stream?.streamId })}
      >
        {stream.value}
      </Control.Link>
    </div>
  );
};

StreamLink.propTypes = {
  source: PropTypes.string,
  availableStreams: PropTypes.arrayOf(PropTypes.any),
  can: PropTypes.func.isRequired,
};

StreamLink.defaultProps = {
  source: undefined,
  availableStreams: [],
};

export default connect(
  (state) => ({
    availableStreams: selectStreamOptions(state),
    can: viewerCan(state),
  }),
)(StreamLink);
