import React from 'react';

import { AgeCard } from '@vlabs/shared/legacy-components/attribute-card/AgeCard';
import { BackpackCard } from '@vlabs/shared/legacy-components/attribute-card/BackpackCard';
import { GenderCard } from '@vlabs/shared/legacy-components/attribute-card/GenderCard';
import { HeadwearCard } from '@vlabs/shared/legacy-components/attribute-card/HeadwearCard';
import { LowerBodyCard } from '@vlabs/shared/legacy-components/attribute-card/LowerBodyCard';
import { ShoesCard } from '@vlabs/shared/legacy-components/attribute-card/ShoesCard';
import { UpperBodyCard } from '@vlabs/shared/legacy-components/attribute-card/UpperBodyCard';
import cn from 'classnames';
import PropTypes from 'prop-types';

import st from './tabs.module.sass';

const BodyAttributesTab = ({
  event,
  className,
}) => {
  const gender = event?.body_basic_attributes?.apparent_gender;
  const age = event?.body_basic_attributes?.apparent_age;
  const upperBody = event?.upper_body;
  const lowerBody = event?.lower_body;
  const backpack = event?.accessories?.backpack;

  return (
    <div className={cn(st.TabPanel, className)}>
      {gender !== undefined && <GenderCard gender={gender} />}
      {age !== undefined && <AgeCard age={age} />}

      {upperBody && <UpperBodyCard values={upperBody} />}
      {lowerBody && <LowerBodyCard values={lowerBody} />}
      {upperBody && (
        <HeadwearCard colors={upperBody.headwear.apparent_color} headwear={upperBody.headwear.state} />
      )}
      {lowerBody && <ShoesCard shoes={lowerBody.shoes} />}
      {backpack && <BackpackCard backpack={backpack.state} />}
    </div>
  );
};

BodyAttributesTab.propTypes = {
  event: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
};

BodyAttributesTab.defaultProps = {
  event: undefined,
  className: undefined,
};

export { BodyAttributesTab };
