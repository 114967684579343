import React from 'react';

import { FileTextIcon } from '@vlabs/icons';
import { selectAppServices } from '@vlabs/shared/selectors/appSelectors';
import { Page, Table, Panel, Divider } from '@vlabs/uikit';
import cn from 'classnames';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import st from './InfoPage.module.sass';

const StateLabelCell = ({ value }) => {
  const { t } = useTranslation();

  const stateList = {
    loading: t('info:stateList.loading'),
    loaded: t('info:stateList.loaded'),
    failed: t('info:stateList.failed'),
  };

  return (
    <>
      <div className={cn(st.State, st[`State_${value}`])} />
      <span>{stateList[value]}</span>
    </>
  );
};

StateLabelCell.propTypes = {
  value: PropTypes.string,
};

StateLabelCell.defaultProps = {
  value: undefined,
};

function UIDocsCell({ value }) {
  if (value === undefined) return null;
  return (
    <a
      aria-label="FileTextIcon"
      className={st.DocsLink}
      href={value}
      title={i18n.t('посмотреть')}
    >
      <FileTextIcon />
    </a>
  );
}

UIDocsCell.propTypes = {
  value: PropTypes.string,
};

UIDocsCell.defaultProps = {
  value: undefined,
};

function InfoPageComponent({
  services,
  showDocumentationColumn,
}) {
  const { t } = useTranslation();
  const data = Object.values(services);

  const columns = [
    { Header: t('info:table.columns.title'), accessor: 'title' },
    { Header: t('info:table.columns.version'), accessor: 'version' },
    { Header: t('info:table.columns.state'), accessor: 'state', Cell: StateLabelCell },
  ];

  if (showDocumentationColumn) {
    columns.push({
      Header: t('info:table.columns.documentation'),
      accessor: 'uiDocsURL',
      Cell: UIDocsCell,
    });
  }

  return (
    <Page title={t('info:title')}>
      <Divider />
      <Panel>
        <Table
          columns={columns}
          data={data}
        />
      </Panel>
    </Page>
  );
}

InfoPageComponent.propTypes = {
  services: PropTypes.shape({}),
  showDocumentationColumn: PropTypes.bool,
};

InfoPageComponent.defaultProps = {
  services: {},
  showDocumentationColumn: true,
};

export const InfoPage = connect((state) => ({
  services: selectAppServices(state),
}))(InfoPageComponent);
