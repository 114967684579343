import React, { useState } from 'react';

import { actionColumnProps } from '@vlabs/shared/utils';
import { Page, Panel, Table, TableCells, Modal, openConfirmPopup, TableFilters } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import DepartmentForm from './DepartmentForm';
import { selectDepartmentsPageData } from './selectors';
import {
  updateFilters, updatePageSize, updatePage,
  createDepartment, updateDepartment, deleteDepartment,
} from './store';

const DepartmentListPageComponent = ({
  departmentsData,
  create,
  update,
  deletion,
  setFilters,
  setPageSize,
  setPageIndex,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(undefined);
  const closeModal = () => {
    setSelectedDepartment(undefined);
    setModalIsOpen(false);
  };

  const onShowDepartment = ({ departmentId }) => {
    history.push(`/departments/${departmentId}`);
  };

  const onShowForm = (department) => {
    if (department) {
      setSelectedDepartment(department);
    }
    setModalIsOpen(true);
  };

  const onFormSubmit = async (data) => {
    try {
      if (selectedDepartment) {
        await update(selectedDepartment.departmentId, data);
        closeModal();
      } else {
        await create(data);
        closeModal();
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast.warning(t('departments:api.duplicate'));
      } else {
        throw error;
      }
    }
  };

  const onDeleteDepartment = (department) => {
    const message = t('departments:confirmation.message', {
      context: 'delete',
      item: department.name,
    });

    const onConfirm = async () => {
      await deletion(department.departmentId);
      closeModal();
    };

    openConfirmPopup({
      title: t('departments:confirmation.title', { context: 'delete' }),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  return (
    <Page className="Global__Table_fullScreen_wrapper">
      <Modal
        appElement={document.getElementById('root')}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <DepartmentForm
          defaultValues={selectedDepartment}
          onSubmit={onFormSubmit}
        />
      </Modal>
      <Panel className="Global__Table_fullScreen">
        <Table
          columns={[
            { Header: t('departments:table.department name'), accessor: 'name', Filter: TableFilters.buildTextFilter() },
            { Header: t('departments:table.department id'), accessor: 'departmentId' },
            actionColumnProps({ id: 'action2', Cell: TableCells.EditCell }),
            actionColumnProps({ id: 'action3', Cell: TableCells.DeleteCell }),
          ]}
          data={departmentsData.data}
          disableSortBy={false}
          filters={departmentsData.filters}
          onActions={{
            onClickRow: { handler: onShowDepartment },
            onAddRow: { handler: onShowForm },
            onEditRow: { handler: onShowForm },
            onDeleteRow: { handler: onDeleteDepartment },
          }}
          pageIndex={departmentsData.pageIndex}
          pageSize={departmentsData.pageSize}
          pageSizeOptions={departmentsData.pageSizeOptions}
          paginationType="offsetBased"
          setFilters={setFilters}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />
      </Panel>
    </Page>
  );
};

export const DepartmentListPage = connect(
  (state) => ({
    departmentsData: selectDepartmentsPageData(state),
  }),
  (dispatch) => ({
    create: (data) => dispatch(createDepartment(data)),
    update: (id, data) => dispatch(updateDepartment(id, data)),
    deletion: (id) => dispatch(deleteDepartment(id)),
    setFilters: (filters) => dispatch(updateFilters({ filters })),
    setPageSize: (pageSize) => dispatch(updatePageSize({ pageSize })),
    setPageIndex: (pageIndex) => dispatch(updatePage({ pageIndex })),
  }),
)(DepartmentListPageComponent);
