/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useContext } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { CircleInfoIcon } from '@vlabs/icons';
import { NewDetectionSelector, detectOnImageRequest } from '@vlabs/shared/components/detection-selector';
import { DETECTION_TYPES } from '@vlabs/shared/components/detection-selector/constants';
import { imageTypes } from '@vlabs/shared/config';
import validate from '@vlabs/shared/validators';
import { ButtonToggle, ButtonToggleGroup, Control, Divider } from '@vlabs/uikit';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SearchContext } from '../search-context';
import st from '../SearchPage.module.sass';
import { EventPreview } from './EventPreview';
import { FacePreview } from './FacePreview';

function ReferenceSelector() {
  const { t } = useTranslation();
  const {
    setMatcherType,
    inputType,
    setInputType,
    setFile,
    file,
    setDetections,
    detections,
    setInputError,
    inputError,
    setInputValue,
    inputValue,
    referenceType,
    referencesPreview,
    setReferenceType,
    setReferences,
    setSelectedDetections,
    selectedDetections,
    resetSearchInfo,
    resetSearchState,
    resetMatchResults,
    setCandidateOrigin,
  } = useContext(SearchContext);
  const isPreviewExists = (file && detections?.all?.length > 0) || referencesPreview?.data?.length > 0;

  useEffect(() => {
    if (detections === undefined
      || detections?.all?.length === 0
      || !(selectedDetections?.length > 0)) return;

    const imageReferences = selectedDetections.map((detectionId) => {
      setMatcherType(detections.byId[detectionId].type);
      if (detections.byId[detectionId].type === 'body') setCandidateOrigin('events');
      return {
        id: detectionId,
        type: referenceType,
        data: detections.byId[detectionId].attributes.descriptor.sdk_descriptor,
      };
    });

    setReferences(imageReferences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detections, selectedDetections, setReferences, referenceType]);

  useEffect(() => {
    if (!file) return;
    async function detectImage() {
      const imageDetections = await detectOnImageRequest(
        file,
        [DETECTION_TYPES.FACES, DETECTION_TYPES.BODIES],
        apiContainer.lunaClient.sdk.estimateFace,
        apiContainer.lunaClient.sdk.estimateBody,
      );
      setDetections(imageDetections);
    }
    detectImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const onReferenceTypeChange = (value) => {
    setReferenceType(value);
    setInputValue('');
    resetSearchInfo();
  };

  const onFileSelect = (newFile) => {
    if (!newFile) return;
    setFile(newFile);
  };

  const resetFile = () => {
    setFile(undefined);
    setDetections(undefined);
    setSelectedDetections(undefined);
    resetSearchState();
  };

  const resetInput = () => {
    resetSearchState();
    setInputError(undefined);
    setInputValue('');
  };

  const setReferencesDebounced = useMemo(
    () => debounce(setReferences, 1000),
    [setReferences],
  );

  const onInputChange = (value, {
    validationError = undefined,
    debounced = false,
  }) => {
    setInputValue(value);
    if (!value) return resetInput();
    if (typeof validationError === 'string') {
      setInputError(validationError);
      return undefined;
    }

    setInputError(undefined);
    resetMatchResults();
    if (debounced) {
      setReferencesDebounced([{ id: value, type: referenceType }]);
    } else {
      setReferences([{ id: value, type: referenceType }]);
    }

    return undefined;
  };

  const onChangeDetections = (v) => {
    resetMatchResults();
    setSelectedDetections(v);
  };

  const referenceTypeByInput = {
    image: 'sdk_descriptor',
    event: 'event',
    face: 'face_external_id',
  };

  const onChangeInputType = (v) => {
    setInputType(v);
    setReferenceType(referenceTypeByInput[v]);
    setInputValue('');
    resetSearchInfo();
    setMatcherType('face');
  };

  return (
    <>
      {/* Выбор типа поиска */}
      <div className={st.InfoCol_flex}>
        {/* Управление типом поиска */}
        <div className={st.ButtonToggleGroup}>
          <ButtonToggleGroup
            className={st.ButtonToggle}
            itemClassName={st.ButtonToggle__Item}
            onChange={onChangeInputType}
            testId="buttonGroup"
            value={inputType}
          >
            <ButtonToggle value="image">{t('search:фотография')}</ButtonToggle>
            <ButtonToggle value="event">{t('search:событие')}</ButtonToggle>
            <ButtonToggle value="face">{t('search:лицо')}</ButtonToggle>
          </ButtonToggleGroup>

          <div className={st.FileSelector_margin}>
            <div className={st.Col}>
              {inputType === 'image' && !isPreviewExists && (
                <Control.FileDropzone
                  data-testid="fileSelector"
                  error={detections?.length === 0 && t('search:ошибка.детекции отсутствуют')}
                  onChangeValue={onFileSelect}
                  onlyImages
                  onReset={resetFile}
                  supportedImageTypes={imageTypes}
                />
              )}

              {inputType === 'event' && (
                <>
                  <Control.Input
                    data-testid="input.eventId"
                    hasError={!!inputError}
                    id="eventId"
                    isClearable
                    label={t('id события')}
                    onChange={(e) => onInputChange(e?.target?.value, {
                      validationError: validate.uuid()(e?.target?.value),
                    })}
                    onClear={resetInput}
                    placeholder={t('id события')}
                    value={inputValue}
                  />
                  {inputError && (<span className={st.ErrorMessage}>{inputError}</span>)}
                </>
              )}

              {inputType === 'face' && (
                <>
                  <div className={st.Subtitle}>{t('id лица')}</div>
                  <ButtonToggleGroup
                    className={st.ButtonToggle}
                    itemClassName={st.ButtonToggle__Item}
                    onChange={onReferenceTypeChange}
                    testId="buttonSubgroup"
                    value={referenceType}
                  >
                    <ButtonToggle value="face_external_id">{t('external id')}</ButtonToggle>
                    <ButtonToggle value="face">{t('LUNA ID')}</ButtonToggle>
                  </ButtonToggleGroup>

                  <Divider small />

                  {referenceType === 'face_external_id' && (
                    <Control.Input
                      data-testid="input.externalId"
                      isClearable
                      onChange={(e) => onInputChange(e?.target?.value, { debounced: true })}
                      onClear={resetInput}
                      placeholder={t('external id')}
                      value={inputValue}
                    />
                  )}

                  {referenceType === 'face' && (
                    <>
                      <Control.Input
                        data-testid="input.faceId"
                        hasError={!!inputError}
                        isClearable
                        onChange={(e) => onInputChange(e?.target?.value, {
                          validationError: validate.uuid()(e?.target?.value),
                        })}
                        onClear={resetInput}
                        placeholder={t('id лица')}
                        value={inputValue}
                      />
                      {inputError && (<span className={st.ErrorMessage}>{inputError}</span>)}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {/* Дополнительная информация */}
        <div className={st.Information}>
          {!isPreviewExists && (
            <div className={st.InfoPanel}>
              <CircleInfoIcon className={st.Icon} />

              <div className="Body-2">
                {referenceType === 'sdk_descriptor' && (
                  <>
                    {t('search:подсказка.загрузка фото')}
                    <br />
                    {t('search:подсказка.загрузка фото условие')}
                  </>
                )}
                {referenceType === 'face_external_id' && (
                  <>
                    {t('search:подсказка.внешний id')}
                    <br />
                    {t('search:подсказка.внешний id условие')}
                  </>
                )}
                {referenceType === 'event' && t('search:подсказка.event id')}
                {referenceType === 'face' && t('search:подсказка.face id')}
              </div>
            </div>
          )}

          {inputType === 'face' && referencesPreview?.data?.length > 0 && (
            <FacePreview />
          )}

          {inputType === 'event' && referencesPreview?.data?.length > 0 && (
            <EventPreview />
          )}

        </div>
      </div>

      {/* Дополнительный блок выбора референса */}
      {
        referenceType === 'sdk_descriptor'
          && file !== undefined
          && detections?.all?.length > 0
          && (
            <div className={st.InfoCol_flex}>
              <NewDetectionSelector
                detections={detections}
                onReset={resetFile}
                onSelect={onChangeDetections}
                photo={file.binary}
              />
            </div>
          )
      }
    </>
  );
}

ReferenceSelector.propTypes = {
  // referenceType: PropTypes.string,
  referencesPreview: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    pageIndex: PropTypes.number,
  }),
};

ReferenceSelector.defaultProps = {
  // referenceType: 'sdk_descriptor',
  referencesPreview: undefined,
};

export { ReferenceSelector };
