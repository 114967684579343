import React from 'react';

import { LIVENESS_OPTIONS } from '@vlabs/api-bindings/src/luna-client/constants';
import { ArrowInCircleRightIcon, ZoomInIcon, PersonIcon, FolderIcon, ListIcon, VideoIcon } from '@vlabs/icons';
import { LivenessImage } from '@vlabs/shared/assets/images';
import { Sample } from '@vlabs/shared/components/sample/Sample';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import ListLink from '@vlabs/shared/legacy-components/list-link/ListLink';
import StreamLink from '@vlabs/shared/legacy-components/stream-link/StreamLink';
import { AdditionalPropertiesTab } from '@vlabs/shared/legacy-components/tabs/AdditionalPropertiesTab';
import { AttributesTab } from '@vlabs/shared/legacy-components/tabs/AttributesTab';
import { BodyAttributesTab } from '@vlabs/shared/legacy-components/tabs/BodyAttributesTab';
import { routes } from '@vlabs/shared/navigation/routes';
import { calcBadgeType, calcSimilarityPercent } from '@vlabs/shared/utils';
import { Tab, Tabs, Badge, Panel, Divider, ButtonScrimGroup, ButtonScrimItem } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, generatePath } from 'react-router-dom';

import { EventBodySample } from './EventBodySample';
import { EventFaceSample } from './EventFaceSample';
import { SearchLink } from './SearchLink';
import st from './TopMatchCard.module.sass';

const buildLink = (linkType, entityId) => {
  // FIXME: а у нас точно может один и тот же id вести и на событие и на лицо?
  if (linkType === 'face') {
    return generatePath(routes.facePage, { faceId: entityId });
  }

  return generatePath(routes.events.eventDetails, { eventId: entityId });
};

const FaceActions = ({
  sampleURL,
  id,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <ButtonScrimGroup>
      <ButtonScrimItem
        as="a"
        disabled={!sampleURL}
        href={sampleURL}
        icon={<ZoomInIcon />}
        title={t('посмотреть в полном размере')}
      />

      <ButtonScrimItem
        as={RouterLink}
        disabled={!id}
        icon={<ArrowInCircleRightIcon />}
        title={t('перейти')}
        to={buildLink(type, id)}
      />
    </ButtonScrimGroup>
  );
};

const FaceTopMatch = ({ event }) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <Sample
          controls={(
            <FaceActions
              id={event.face_id}
              sampleURL={event?.uiAvatarURL}
              type={event.type}
            />
          )}
          data-testid="topMatchSample"
          image={event?.uiAvatarURL}
          isDeleted={event?.isDeleted}
          key={event?.uiAvatarURL}
          type="face"
        />
      </div>
      <div>
        <Tabs testId="topMatchInfoTabs">
          <Tab
            header={t('атрибуты')}
            headerClassName={st.TabHeader}
            name="topMatchAttributes"
          >
            <AttributesTab {...event} />
          </Tab>

          <Tab
            header={t('дополнительные свойства')}
            headerClassName={st.TabHeader}
            name="topMatchAdditionalProperties"
          >
            <AdditionalPropertiesTab {...event?.detection} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

const EventTopMatch = ({ event }) => {
  const { t } = useTranslation();
  // FIXME: временное решение, удалить во время рефакторинга раздела
  if (event?.isDeleted) {
    return (
      <span>{t('events:event deleted')}</span>
    );
  }

  return (
    <>
      <div>
        {event.face_detections && (
          <EventFaceSample
            actions={(
              <FaceActions
                id={event?.event_id}
                sampleURL={event?.uiFaceDetection?.uiSampleURL}
                type={event?.type}
              />
            )}
            event={event}
          />
        )}
        {event.body_detections && <EventBodySample event={event} />}
      </div>
      <div>
        <Tabs testId="topMatchInfoTabs">
          <Tab
            header={t('атрибуты')}
            headerClassName={st.TabHeader}
            name="topMatchAttributes"
          >
            {event.face_detections && <AttributesTab {...event} />}
            {event.body_detections && <BodyAttributesTab event={event} />}
          </Tab>

          <Tab
            header={t('дополнительные свойства')}
            headerClassName={st.TabHeader}
            name="topMatchAdditionalProperties"
          >
            <AdditionalPropertiesTab {...event?.detection} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export function TopMatchCard({ event }) {
  const { t } = useTranslation();
  if (!event) return null;

  return (
    <Panel className={st.Root}>
      <div className={st.TitleBlock}>
        <div className={st.TitleBlock}>
          <h6>{t(`events:top match card.title.${event?.type}`)}</h6>
          <Badge
            className={st.Badge}
            kind={calcBadgeType(event?.similarity)}
            value={calcSimilarityPercent(event?.similarity)}
          />
        </div>
        <SearchLink event={event} />
      </div>

      <div>
        {event.type === 'event' && (
          <>
            <InfoRow
              className={st.InlineRow}
              icon={(
                <img
                  alt="liveness icon"
                  className="StaticIcon"
                  src={LivenessImage}
                />
              )}
              title={`${t('Liveness')}:`}
              value={LIVENESS_OPTIONS[event.liveness]?.label}
            />
            <InfoRow
              className={st.InlineRow}
              icon={<VideoIcon className="StaticIcon" />}
              title={`${t('source')}:`}
              value={<StreamLink source={event.source} />}
            />
          </>
        )}
        {event.type === 'face' && (
          <>
            <InfoRow
              className={st.InlineRow}
              icon={<FolderIcon className="StaticIcon" />}
              title={`${t('information')}:`}
              value={event.user_data}
            />
            <InfoRow
              className={st.InlineRow}
              icon={<PersonIcon className="StaticIcon" />}
              title={`${t('external id')}:`}
              value={event.external_id}
            />
            {!!event?.lists?.length && (
              <InfoRow
                className={st.ListInfoRow}
                icon={<ListIcon className="StaticIcon" />}
                title={`${t('списки')} (${event.lists.length}):`}
                value={<ListLink lists={event.lists} />}
              />
            )}
          </>
        )}
      </div>

      <Divider small />

      <div className={st.Content}>
        {event.type === 'face' && <FaceTopMatch event={event} />}
        {event.type === 'event' && <EventTopMatch event={event} />}
      </div>
    </Panel>
  );
}
