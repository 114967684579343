import React from 'react';

import { EmptyImage } from '@vlabs/shared/components/empty-image';
import { Sample } from '@vlabs/shared/components/sample/Sample';
import { FaceAction } from '@vlabs/shared/legacy-components/action-button-groups/FaceAction';
import { AdditionalPropertiesTab } from '@vlabs/shared/legacy-components/tabs/AdditionalPropertiesTab';
import { AttributesTab } from '@vlabs/shared/legacy-components/tabs/AttributesTab';
import { routes } from '@vlabs/shared/navigation/routes';
import { Page, Panel, Divider, Grid, GridRow, GridCol, Tabs, Tab, openConfirmPopup, useModal } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { selectAccountRole } from '@vlabs/pages/auth/selectors';

import { FaceInfo, FaceActions, FaceEditForm, PhotoForm } from './components/index';
import st from './FacePage.module.sass';
import {
  selectFaceId,
  selectFaceWithAttributes,
} from './selectors';
import * as storeActions from './store';
import { CreatedFromEventTab } from './tabs/CreatedFromEventTab';
import { EventsTableTab, FacesTableTab } from './tabs/index';

function FacePage({
  faceId,
  face,
  role,
  updateFaceAttributes,
  updateFace,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const editPhotoModal = useModal();
  const editFaceInfoModal = useModal();

  const onFaceDelete = () => {
    const message = t('faces:вы уверены что хотите удалить лицо');

    const onConfirm = async () => {
      await storeActions.deleteFace(faceId);
      history.push(generatePath(routes.faces.all));
    };

    openConfirmPopup({
      title: t('faces:удаление лица'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const onPhotoFormSubmit = async (data) => {
    await updateFaceAttributes({ ...data, face_id: faceId });
    editPhotoModal.close();
  };

  const onSaveFaceInfo = async (values) => {
    await updateFace(values);
    editFaceInfoModal.close();
  };

  return (
    <Page
      data-testid="facePage"
      title={t('faces:детали лица')}
    >
      {editPhotoModal.wrap(<PhotoForm data-testid="photoForm" onSubmit={onPhotoFormSubmit} />)}
      {editFaceInfoModal.wrap(<FaceEditForm
        data-testid="faceEditForm"
        defaultValues={face}
        onSubmit={onSaveFaceInfo}
      />)}

      <Divider small />

      <Grid>
        <GridRow>
          <GridCol cols={5}>
            <Divider />
            <Grid>
              <Panel>
                <GridRow>
                  <GridCol cols={5}>
                    <div className={st.ImageWrapper}>
                      {face?.uiAvatarURL
                        ? (
                          <Sample
                            controls={<FaceAction imageUrl={face.uiAvatarURL} />}
                            data-testid="faceSample"
                            image={face.uiAvatarURL}
                            key={face.uiAvatarURL}
                          />
                        )
                        : (
                          <EmptyImage className={st.Empty_Image} />
                        )}
                    </div>
                  </GridCol>
                  <GridCol cols={7}>
                    <FaceActions
                      data-testid="faceActions"
                      faceId={faceId}
                      onEditFaceInfo={editFaceInfoModal.open}
                      onEditPhoto={editPhotoModal.open}
                      onFaceDelete={onFaceDelete}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <FaceInfo data-testid="faceInfo" face={face} />
                  <Tabs testId="faceInfoTabs">
                    <Tab
                      header={t('атрибуты лица')}
                      headerType="fullWidth"
                      name="basic attributes"
                    >
                      <AttributesTab {...face?.basic_attributes} />
                    </Tab>
                    <Tab
                      header={t('дополнительные свойства')}
                      headerType="fullWidth"
                      name="additional properties"
                    >
                      <AdditionalPropertiesTab {...face} />
                    </Tab>
                  </Tabs>
                </GridRow>
              </Panel>
            </Grid>
          </GridCol>

          <GridCol cols={7}>
            <Panel>
              <Tabs testId="tableTabs">
                {face?.event_id && (
                  <Tab className={st.TabHeader} header={t('faces:created from event.title')} headerType="fullWidth" name="created from event">
                    <CreatedFromEventTab {...face} />
                  </Tab>
                )}

                <Tab
                  className={st.TabHeader}
                  header={t('faces:лица с тем же внешним id')}
                  headerType="fullWidth"
                  name="face with the same externalId"
                >
                  <FacesTableTab {...face} />
                </Tab>
                <Tab
                  className={st.TabHeader}
                  header={t('faces:последние события с данным лицом')}
                  headerType="fullWidth"
                  name="last events with the same face"
                >
                  <EventsTableTab role={role} {...face} />
                </Tab>
              </Tabs>
            </Panel>
          </GridCol>
        </GridRow>
      </Grid>
    </Page>
  );
}

FacePage.propTypes = {
  faceId: PropTypes.string,
  face: PropTypes.shape({
    face_id: PropTypes.string,
    external_id: PropTypes.string,
    user_data: PropTypes.string,
    uiAvatarURL: PropTypes.string,
    basic_attributes: PropTypes.shape({}),
    event_id: PropTypes.string,
  }),
  role: PropTypes.number,
  updateFaceAttributes: PropTypes.func,
  updateFace: PropTypes.func,
};

FacePage.defaultProps = {
  faceId: undefined,
  face: {},
  role: null,
  updateFaceAttributes: undefined,
  updateFace: undefined,
};

export default connect(
  (state) => ({
    faceId: selectFaceId(state),
    face: selectFaceWithAttributes(state),
    role: selectAccountRole(state),
  }),
  (dispatch) => ({
    updateFaceAttributes: (params) => dispatch(storeActions.updateFaceAttributes(params)),
    updateFace: (face, params) => dispatch(storeActions.updateFace(face, params)),
  }),
)(FacePage);
