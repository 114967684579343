import {
  DEEPFAKE_MODE_OPTIONS,
  DEEPFAKE_STATE_OPTIONS_STRICT,
  MASK_OPTIONS,
  MULTIFACE_POLICY_OPTIONS,
} from '../../../constants';
import { Schema, get } from '../../../json-transform';
import { LIVENESS_OPTIONS } from '../../constants';
import {
  FACE_QUALITY_EYE,
  FACE_QUALITY_EYEBROWS_STATE,
  FACE_QUALITY_FACE_COLOR_TYPES,
  FACE_QUALITY_GLASSES,
  FACE_QUALITY_HEADWEAR_TYPE,
  FACE_QUALITY_IMAGE_FORMAT,
  FACE_QUALITY_SMILE_PROPERTIES,
  FACE_QUALITY_YES_NO,
} from '../../handlers/constants';

const faceQuality = new Schema({
  imageFormat: {
    isEnabled: get('policies.detect_policy.face_quality.checks.image_format.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.image_format.threshold', []).asArrayOfOptions(FACE_QUALITY_IMAGE_FORMAT),
  },
  illuminationQuality: {
    isEnabled: get('policies.detect_policy.face_quality.checks.illumination_quality.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.illumination_quality.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.illumination_quality.threshold.max').asFloat(),
    },
  },
  specularityQuality: {
    isEnabled: get('policies.detect_policy.face_quality.checks.specularity_quality.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.specularity_quality.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.specularity_quality.threshold.max').asFloat(),
    },
  },
  blurrinessQuality: {
    isEnabled: get('policies.detect_policy.face_quality.checks.blurriness_quality.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.blurriness_quality.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.blurriness_quality.threshold.max').asFloat(),
    },
  },
  darkQuality: {
    isEnabled: get('policies.detect_policy.face_quality.checks.dark_quality.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.dark_quality.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.dark_quality.threshold.max').asFloat(),
    },
  },
  lightQuality: {
    isEnabled: get('policies.detect_policy.face_quality.checks.light_quality.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.light_quality.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.light_quality.threshold.max').asFloat(),
    },
  },
  headYaw: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_yaw.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_yaw.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_yaw.threshold.max').asFloat(),
    },
  },
  headPitch: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_pitch.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_pitch.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_pitch.threshold.max').asFloat(),
    },
  },
  headRoll: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_roll.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_roll.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_roll.threshold.max').asFloat(),
    },
  },
  gazeYaw: {
    isEnabled: get('policies.detect_policy.face_quality.checks.gaze_yaw.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.gaze_yaw.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.gaze_yaw.threshold.max').asFloat(),
    },
  },
  gazePitch: {
    isEnabled: get('policies.detect_policy.face_quality.checks.gaze_pitch.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.gaze_pitch.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.gaze_pitch.threshold.max').asFloat(),
    },
  },
  mouthSmiling: {
    isEnabled: get('policies.detect_policy.face_quality.checks.mouth_smiling.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.mouth_smiling.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.mouth_smiling.threshold.max').asFloat(),
    },
  },
  mouthOccluded: {
    isEnabled: get('policies.detect_policy.face_quality.checks.mouth_occluded.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.mouth_occluded.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.mouth_occluded.threshold.max').asFloat(),
    },
  },
  mouthOpen: {
    isEnabled: get('policies.detect_policy.face_quality.checks.mouth_open.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.mouth_open.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.mouth_open.threshold.max').asFloat(),
    },
  },
  glasses: {
    isEnabled: get('policies.detect_policy.face_quality.checks.glasses.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.glasses.threshold', []).asArrayOfOptions(FACE_QUALITY_GLASSES),
  },
  leftEye: {
    isEnabled: get('policies.detect_policy.face_quality.checks.left_eye.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.left_eye.threshold', []).asArrayOfOptions(FACE_QUALITY_EYE),
  },
  rightEye: {
    isEnabled: get('policies.detect_policy.face_quality.checks.right_eye.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.right_eye.threshold', []).asArrayOfOptions(FACE_QUALITY_EYE),
  },
  headHorizontalCenter: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_horizontal_center.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_horizontal_center.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_horizontal_center.threshold.max').asFloat(),
    },
  },
  headVerticalCenter: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_vertical_center.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_vertical_center.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_vertical_center.threshold.max').asFloat(),
    },
  },
  headWidth: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_width.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_width.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_width.threshold.max').asFloat(),
    },
  },
  headHeight: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_height.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_height.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_height.threshold.max').asFloat(),
    },
  },
  eyeDistance: {
    isEnabled: get('policies.detect_policy.face_quality.checks.eye_distance.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.eye_distance.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.eye_distance.threshold.max').asFloat(),
    },
  },
  imageWidth: {
    isEnabled: get('policies.detect_policy.face_quality.checks.image_width.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.image_width.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.image_width.threshold.max').asInt(),
    },
  },
  imageHeight: {
    isEnabled: get('policies.detect_policy.face_quality.checks.image_height.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.image_height.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.image_height.threshold.max').asInt(),
    },
  },
  aspectRatio: {
    isEnabled: get('policies.detect_policy.face_quality.checks.aspect_ratio.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.aspect_ratio.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.aspect_ratio.threshold.max').asFloat(),
    },
  },
  faceWidth: {
    isEnabled: get('policies.detect_policy.face_quality.checks.face_width.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.face_width.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.face_width.threshold.max').asInt(),
    },
  },
  faceHeight: {
    isEnabled: get('policies.detect_policy.face_quality.checks.face_height.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.face_height.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.face_height.threshold.max').asInt(),
    },
  },
  indentLeft: {
    isEnabled: get('policies.detect_policy.face_quality.checks.indent_left.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.indent_left.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.indent_left.threshold.max').asInt(),
    },
  },
  indentRight: {
    isEnabled: get('policies.detect_policy.face_quality.checks.indent_right.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.indent_right.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.indent_right.threshold.max').asInt(),
    },
  },
  indentUpper: {
    isEnabled: get('policies.detect_policy.face_quality.checks.indent_upper.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.indent_upper.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.indent_upper.threshold.max').asInt(),
    },
  },
  indentLower: {
    isEnabled: get('policies.detect_policy.face_quality.checks.indent_lower.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.indent_lower.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.indent_lower.threshold.max').asInt(),
    },
  },
  imageSize: {
    isEnabled: get('policies.detect_policy.face_quality.checks.image_size.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.image_size.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.image_size.threshold.max').asInt(),
    },
  },
  eyebrowsState: {
    isEnabled: get('policies.detect_policy.face_quality.checks.eyebrows_state.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.eyebrows_state.threshold', []).asArrayOfOptions(FACE_QUALITY_EYEBROWS_STATE),
  },
  headwearType: {
    isEnabled: get('policies.detect_policy.face_quality.checks.headwear_type.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.headwear_type.threshold', []).asArrayOfOptions(FACE_QUALITY_HEADWEAR_TYPE),
  },
  smileProperties: {
    isEnabled: get('policies.detect_policy.face_quality.checks.smile_properties.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.smile_properties.threshold', []).asArrayOfOptions(FACE_QUALITY_SMILE_PROPERTIES),
  },
  faceColorType: {
    isEnabled: get('policies.detect_policy.face_quality.checks.face_color_type.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.face_color_type.threshold', []).asArrayOfOptions(FACE_QUALITY_FACE_COLOR_TYPES),
  },
  naturalLight: {
    isEnabled: get('policies.detect_policy.face_quality.checks.natural_light.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.natural_light.threshold').asOption(FACE_QUALITY_YES_NO),
  },
  redEyes: {
    isEnabled: get('policies.detect_policy.face_quality.checks.red_eyes.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.red_eyes.threshold').asOption(FACE_QUALITY_YES_NO),
  },
  radialDistortion: {
    isEnabled: get('policies.detect_policy.face_quality.checks.radial_distortion.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.radial_distortion.threshold').asOption(FACE_QUALITY_YES_NO),
  },
  illuminationUniformity: {
    isEnabled: get('policies.detect_policy.face_quality.checks.illumination_uniformity.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.illumination_uniformity.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.illumination_uniformity.threshold.max').asFloat(),
    },
  },
  dynamicRange: {
    isEnabled: get('policies.detect_policy.face_quality.checks.dynamic_range.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.dynamic_range.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.dynamic_range.threshold.max').asFloat(),
    },
  },
});

export const read = new Schema({
  accountId: get('account_id').asString(),
  verifierId: get('verifier_id').asString(),
  description: get('description').asString(),
  createTime: get('create_time').asDate(),
  lastUpdateTime: get('last_update_time').asDate(),
  verificationThreshold: get('policies.verification_threshold').asFloat().ifNaN(undefined),
  estimate: {
    headPose: get('policies.detect_policy.estimate_head_pose').asBoolean(),
    emotion: get('policies.detect_policy.estimate_emotions').asBoolean(),
    mask: get('policies.detect_policy.estimate_mask').asBoolean(),
    quality: get('policies.detect_policy.estimate_quality').asBoolean(),
    gaze: get('policies.detect_policy.estimate_gaze').asBoolean(),
    glasses: get('policies.detect_policy.estimate_glasses').asBoolean(),
    eyes: get('policies.detect_policy.estimate_eyes_attributes').asBoolean(),
    mouth: get('policies.detect_policy.estimate_mouth_attributes').asBoolean(),
    landmarks68: get('policies.detect_policy.detect_landmarks68').asBoolean(),
    faceQuality: get('policies.detect_policy.face_quality.estimate').asBoolean(),
  },
  extract: {
    basicAttributes: get('policies.extract_policy.extract_basic_attributes').asBoolean(),
    exif: get('policies.detect_policy.extract_exif').asBoolean(),
  },
  filters: {
    multifacePolicy: get('policies.detect_policy.multiface_policy').asOption(MULTIFACE_POLICY_OPTIONS),
    faceDescriptorScoreThreshold: get('policies.extract_policy.fd_score_threshold').asFloat().ifNaN(undefined),
    masks: get('policies.detect_policy.mask_states', []).ifNull([]).asArrayOfOptions(MASK_OPTIONS),
    pitchThreshold: get('policies.detect_policy.pitch_threshold').asFloat().ifNaN(undefined),
    rollThreshold: get('policies.detect_policy.roll_threshold').asFloat().ifNaN(undefined),
    yawThreshold: get('policies.detect_policy.yaw_threshold').asFloat().ifNaN(undefined),
    liveness_states: get('policies.detect_policy.liveness_states', []).ifNull([]).asArrayOfOptions(LIVENESS_OPTIONS),
    deepfake_states: get('policies.detect_policy.deepfake_states', []).ifNull([]).asArrayOfOptions(DEEPFAKE_STATE_OPTIONS_STRICT),
    faceQuality: get('policies.detect_policy.face_quality.filter').asBoolean(),
  },
  faceSamplePolicy: {
    isEnabled: get('policies.storage_policy.face_sample_policy.store_sample').asBoolean(),
  },
  attributesPolicy: {
    isEnabled: get('policies.storage_policy.attribute_policy.store_attribute').asBoolean(),
  },
  estimate_deepfake: {
    estimate: get('policies.detect_policy.estimate_deepfake.estimate', false).asBoolean(),
    real_threshold: get('policies.detect_policy.estimate_deepfake.real_threshold').asFloat(),
    mode: get('policies.detect_policy.estimate_deepfake.mode').asOption(DEEPFAKE_MODE_OPTIONS),
  },
  estimate_liveness: {
    estimate: get('policies.detect_policy.estimate_liveness.estimate').asBoolean(),
    liveness_threshold: get('policies.detect_policy.estimate_liveness.liveness_threshold').asFloat().ifNaN(undefined),
    quality_threshold: get('policies.detect_policy.estimate_liveness.quality_threshold').asFloat().ifNaN(undefined),
  },
  faceQuality,
});
