import { DateCell } from './DateCell';
import { DeleteCell } from './DeleteCell';
import { EditCell } from './EditCell';
import { ExportCell } from './ExportCell';
import { IdCell } from './IdCell';
import { ImportCell } from './ImportCell';
import { SelectAllRowsCell } from './SelectAllRowsCell';
import { SelectRowCell } from './SelectRowCell';
import { ThumbCell } from './ThumbCell';

export const TableCells = {
  DateCell,
  DeleteCell,
  EditCell,
  ExportCell,
  IdCell,
  ThumbCell,
  ImportCell,
  SelectRowCell,
  SelectAllRowsCell,
};
