import React, { useMemo } from 'react';

import { PlayerPlayIcon } from '@vlabs/icons';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { actionColumnProps } from '@vlabs/shared/utils';
import { Control, Page, Panel, Table, TableCells, openConfirmPopup } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { selectPageData } from './selectors';
import * as verifierApi from './store';

const VerifyCell = ({ row }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Control.RoundButton
      data-testid="linkCell"
      icon={<PlayerPlayIcon />}
      kind="primary"
      onClick={() => history.push(generatePath(routes.verifiers.verify, { verifierId: row.original.verifierId }))}
      title={t('verifiers:таблица.кнопки.тестировать верификатор')}
      variant="dimmed"
    />
  );
};

function VerifierListPage({
  data,
  setPageIndex,
  setPageSize,
  deleteVerifier,
  onEditVerifier,
  onCreateVerifier,
  can,
}) {
  const { t } = useTranslation();

  const canVerifierModification = useMemo(() => can(permissions.verifier.modification), [can]);

  const canVerifierDeletion = useMemo(() => can(permissions.verifier.deletion), [can]);

  const canVerifierCreation = useMemo(() => can(permissions.verifier.creation), [can]);

  const onDeleteVerifier = (verifier) => {
    const message = <span>{t('verifiers:таблица.подтверждение.удаление.текст', { verifier })}</span>;

    const onConfirm = async () => {
      deleteVerifier(verifier.verifierId);
    };

    openConfirmPopup({
      title: t('verifiers:таблица.подтверждение.удаление.заголовок'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const columns = useMemo(() => {
    const colsList = [
      { Header: t('verifiers:таблица.колонки.description'), accessor: 'description' },
      { Header: t('verifiers:таблица.колонки.verifier_id'), accessor: 'verifierId' },
      actionColumnProps({ id: 'verify', Cell: VerifyCell }),
    ];

    if (canVerifierModification) {
      const editCell = actionColumnProps({ id: 'edit', Cell: TableCells.EditCell });
      colsList.push(editCell);
    }
    if (canVerifierDeletion) {
      const deleteCell = actionColumnProps({ id: 'delete', Cell: TableCells.DeleteCell });
      colsList.push(deleteCell);
    }

    return colsList;
  }, [t, canVerifierModification, canVerifierDeletion]);

  return (
    <Page className="Global__Table_fullScreen_wrapper">
      <Panel className="Global__Table_fullScreen">
        <Table
          columns={columns}
          data={data.data}
          meta={data.meta}
          onActions={{
            onAddRow: { handler: onCreateVerifier, can: canVerifierCreation },
            onEditRow: { handler: onEditVerifier, can: canVerifierModification },
            onDeleteRow: { handler: onDeleteVerifier, can: canVerifierDeletion },
          }}
          pageIndex={data.pageIndex}
          pageSize={data.pageSize}
          pageSizeOptions={data.pageSizeOptions}
          paginationType="offsetBased"
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />
      </Panel>
    </Page>
  );
}

export default connect((state) => ({
  data: selectPageData(state),
  can: viewerCan(state),
}),
(dispatch, { history }) => ({
  deleteVerifier: (id) => dispatch(verifierApi.deleteVerifier(id)),
  setPageSize: (pageSize) => dispatch(verifierApi.updatePageSize({ pageSize })),
  setPageIndex: (pageIndex) => dispatch(verifierApi.updatePage({ pageIndex })),
  onEditVerifier: ({ verifierId }) => history.push(generatePath(routes.verifiers.read, { verifierId })),
  onCreateVerifier: () => history.push(generatePath(routes.verifiers.create)),
}))(VerifierListPage);
