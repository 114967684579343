import React, { useMemo } from 'react';

import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { selectAppServiceState } from '@vlabs/shared/selectors/appSelectors';
import { Page, Tabs, Tab, Panel } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import LunaStreamsStreamList from './luna-streams/features/streams/StreamListTable';
import VLAccessSourceListTable from './vl-access/SourceListTable';

import './styles.sass';

function StreamListPage({
  vlAccessIsAvailable,
  lunaStreamsIsAvailable,
  lunaStreamsProps,
  can,
}) {
  const { t } = useTranslation();

  const canSourceView = useMemo(() => can(permissions.source.view), [can]);
  const canSourceModification = useMemo(() => can(permissions.source.modification), [can]);
  const canSourceDeletion = useMemo(() => can(permissions.source.deletion), [can]);

  const hiddenColumns = useMemo(() => [
    !canSourceView && 'live',
    !canSourceModification && 'start',
    !canSourceModification && 'pause',
    !canSourceModification && 'stop',
    !canSourceModification && 'edit',
    !canSourceDeletion && 'delete',
  ].filter((v) => v),
  [canSourceDeletion, canSourceModification, canSourceView]);

  return (
    <Page className="Global__Table_fullScreen_wrapper">
      <Panel className="StreamListPage__Table_fullScreen">
        <Tabs testId="camTabs">
          {lunaStreamsIsAvailable && (
            <Tab className="Tabs__Headers" header={t('Luna Streams')} name="lunaStreams">
              <LunaStreamsStreamList
                hiddenColumns={hiddenColumns}
                withActionButtons={can(permissions.source.creation)}
                {...lunaStreamsProps}
              />
            </Tab>
          )}

          {vlAccessIsAvailable && (
            <Tab className="Tabs__Headers" header={t('VL Access')} name="vlAccess">
              <VLAccessSourceListTable />
            </Tab>
          )}
        </Tabs>
      </Panel>
    </Page>
  );
}

StreamListPage.propTypes = {
  vlAccessIsAvailable: PropTypes.bool,
  lunaStreamsIsAvailable: PropTypes.bool,
  lunaStreamsProps: PropTypes.shape({
    onAddStreamClick: PropTypes.func,
    onEditStreamClick: PropTypes.func,
  }),
  can: PropTypes.func.isRequired,
};
StreamListPage.defaultProps = {
  vlAccessIsAvailable: false,
  lunaStreamsIsAvailable: false,
  lunaStreamsProps: {},
};

export default connect((state, { history }) => ({
  lunaStreamsIsAvailable: selectAppServiceState(state, 'lunaStreams') === 'loaded',
  vlAccessIsAvailable: selectAppServiceState(state, 'vlAccess') === 'loaded',
  lunaStreamsProps: ({
    onAddStreamClick: () => { history.push(generatePath(routes.sources.lunaStreams.streams.create)); },
    onEditStreamClick: (streamId) => history.push(generatePath(routes.sources.lunaStreams.streams.edit, { streamId })),
  }),
  can: viewerCan(state),
}))(StreamListPage);
