import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import { fetchFaces as getFacesFromApi } from '@vlabs/shared/requests';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { updateFacesList } from '@vlabs/pages/lists/store';

const initialState = {
  state: undefined,
  data: [],
  meta: {
    count: 0,
  },
  filters: [],
  pageIndex: 0,
  pageSize: 25,
  listId: undefined,
  pageSizeOptions: [10, 25, 50, 100],
};

const store = createSlice({
  name: 'faces',
  initialState,
  reducers: {
    setFaces(state, { payload }) {
      state.data = payload.data;
      state.meta.count = payload.facesCount;
      state.state = 'loaded';
    },
    setFilters(state, { payload: { filters } = {} }) {
      if (filters !== undefined) state.filters = filters;
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } = {} }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
    // eslint-disable-next-line consistent-return
    setListId(state, { payload }) {
      if (payload === undefined) return initialState;
      state.listId = payload;
    },
  },
});

export default store.reducer;

export const { setFaces, setPageSize, setPage, setFilters, setListId } = store.actions;

export const fetchFaces = async (dispatch, getState) => {
  const {
    faces: { pageIndex, pageSize, filters, listId },
  } = getState();

  if (!listId) return;

  const localFilters = filters.map((f) => {
    if (f.id === 'external_id') return { ...f, id: 'external_ids' };
    return f;
  });

  const faces = await getFacesFromApi(pageSize, pageIndex, localFilters, listId);
  dispatch(setFaces(faces));
};

export const fetchFaceById = async (id) => Promise.all([
  apiContainer.lunaClient.faces.get(id),
  apiContainer.lunaClient.faces.attributes(id).get(),
]).then(([
  { data: faceData },
  { data: { attributes: attributesData } },
]) => ({ faceData, attributesData }));

export const createFace = (faceData) => async (dispatch) => {
  await apiContainer.lunaClient.faces.create(faceData, { extract_basic_attributes: 1 });
  toast.success(`${i18next.t('faces:создание.успех')}.`);
  dispatch(fetchFaces);
};

export const deleteFaces = (data) => async (dispatch) => {
  let faceIds = null;
  if (data instanceof Array) {
    faceIds = data.map((i) => i.face_id);
  } else if (typeof data === 'object' || data instanceof Object) {
    faceIds = [data.face_id];
  }
  await apiContainer.lunaClient.faces.deleteMany(faceIds);
  toast.success(`${i18next.t('faces:удаление.успех', { count: faceIds.length })}.`);

  dispatch(fetchFaces);
};

export const detachFaces = (id, data) => async (dispatch) => {
  let faceIds = null;
  if (data instanceof Array) {
    faceIds = data.map((i) => i.face_id);
  } else if (typeof data === 'object' || data instanceof Object) {
    faceIds = [data.face_id];
  }
  await updateFacesList(id, {
    action: 'detach',
    face_ids: faceIds,
  });
  dispatch(fetchFaces);
};

export const updateLists = async (faceId, prevLists, newLists) => {
  const listsToAttach = newLists?.filter((listId) => !prevLists.includes(listId));
  const listsToDetach = prevLists?.filter((listId) => !newLists.includes(listId));
  await Promise.all([
    listsToAttach?.map((listId) => updateFacesList(listId, {
      action: 'attach',
      face_ids: [faceId],
    })),
    listsToDetach?.map((listId) => updateFacesList(listId, {
      action: 'detach',
      face_ids: [faceId],
    })),
  ]);
};

export const updateFace = (
  { face_id: faceId, lists: prevLists },
  { lists: newLists, ...params },
) => async (dispatch) => {
  await apiContainer.lunaClient.faces.patch(faceId, params);
  await updateLists(faceId, prevLists, newLists);
  setTimeout(async () => {
    dispatch(fetchFaces);
    toast.success(i18next.t('faces:обновление.успех'));
  }, 100);
};

export const getAttributes = async (faceId) => apiContainer.lunaClient.faces.attributes(faceId).get()
  .then(({ data: { attributes } }) => attributes);

export const getFaceUrl = (sampleId) => apiContainer.lunaClient.samples.getURL('faces', sampleId);

export const updateFilters = (params) => async (dispatch) => {
  dispatch(setFilters(params));
  dispatch(fetchFaces);
};

export const updatePageSize = (params) => async (dispatch) => {
  dispatch(setPageSize(params));
  dispatch(fetchFaces);
};

export const updatePage = (params) => async (dispatch) => {
  dispatch(setPage(params));
  dispatch(fetchFaces);
};

export const updateListId = (listId) => async (dispatch, getState) => {
  const { faces } = getState();
  if (faces.listId === listId) return;
  dispatch(setListId(listId));
  dispatch(fetchFaces);
};
