import React from 'react';

import { TEMPERATURE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { AgeCard } from '@vlabs/shared/legacy-components/attribute-card/AgeCard';
import { BackpackCard } from '@vlabs/shared/legacy-components/attribute-card/BackpackCard';
import { GenderCard } from '@vlabs/shared/legacy-components/attribute-card/GenderCard';
import { HeadwearCard } from '@vlabs/shared/legacy-components/attribute-card/HeadwearCard';
import { LowerBodyCard } from '@vlabs/shared/legacy-components/attribute-card/LowerBodyCard';
import { TemperatureCard } from '@vlabs/shared/legacy-components/attribute-card/TemperatureCard';
import { UpperBodyCard } from '@vlabs/shared/legacy-components/attribute-card/UpperBodyCard';
import PropTypes from 'prop-types';

import st from './AttributesCell.module.sass';

const parseJSON = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
};

export const AttributesCell = ({
  row: {
    original: {
      gender,
      age,
      upper_body: upperBody,
      lower_body: lowerBody,
      accessories,
      user_data: userData,
      tags,
    },
  },
}) => {
  const temperature = parseJSON(userData)?.t?.toFixed(1);
  const thermoTag = tags?.find((tag) => TEMPERATURE_OPTIONS[tag]);

  return (
    <div className={st.Root}>
      {(age || gender || temperature) && (
        <div className={st.Attributes}>
          {gender !== undefined && <GenderCard gender={gender} />}
          {age !== undefined && <AgeCard age={age} />}
          {temperature && thermoTag && <TemperatureCard tag={thermoTag} temperature={temperature} />}
        </div>
      )}

      <div className={st.BodyContainer}>
        {upperBody && <UpperBodyCard values={upperBody} />}
        {lowerBody && <LowerBodyCard values={lowerBody} />}
        {upperBody && (
          <HeadwearCard colors={upperBody?.headwear.apparent_color} headwear={upperBody?.headwear.state} />
        )}
        {accessories?.backpack && <BackpackCard backpack={accessories?.backpack?.state} />}
      </div>
    </div>
  );
};

AttributesCell.propTypes = {
  row: PropTypes.objectOf(PropTypes.any),
};

AttributesCell.defaultProps = {
  row: PropTypes.objectOf(PropTypes.any),
};
