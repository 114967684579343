import { get, CleanSchema } from '../../../json-transform';

const optionValue = (option) => option?.value;

export const create = new CleanSchema({
  description: get('description'),
  policies: {
    verification_threshold: get('verificationThreshold').asFloat().ifNaN(undefined),
    detect_policy: {
      multiface_policy: get('filters.multifacePolicy.value').asInt().ifNaN(undefined),
      estimate_head_pose: get('estimate.headPose').asInt().ifNaN(undefined),
      estimate_emotions: get('estimate.emotion').asInt().ifNaN(undefined),
      estimate_mask: get('estimate.mask').asInt().ifNaN(undefined),
      estimate_quality: get('estimate.quality').asInt().ifNaN(undefined),
      estimate_gaze: get('estimate.gaze').asInt().ifNaN(undefined),
      estimate_glasses: get('estimate.glasses').asInt().ifNaN(undefined),
      estimate_eyes_attributes: get('estimate.eyes').asInt().ifNaN(undefined),
      estimate_mouth_attributes: get('estimate.mouth').asInt().ifNaN(undefined),
      detect_landmarks68: get('estimate.landmarks68').asInt().ifNaN(undefined),
      extract_exif: get('extract.exif').asInt().ifNaN(undefined),
      detect_face: get('estimate.face').asInt().ifNaN(undefined),
      detect_body: get('estimate.body').asInt().ifNaN(undefined),
      pitch_threshold: get('filters.pitchThreshold').asFloat().ifNaN(undefined),
      roll_threshold: get('filters.rollThreshold').asFloat().ifNaN(undefined),
      yaw_threshold: get('filters.yawThreshold').asFloat().ifNaN(undefined),
      mask_states: get('filters.masks').asArrayOf(optionValue).ifEmpty(undefined),
      estimate_liveness: {
        estimate: get('estimate_liveness.estimate').asInt().ifNaN(undefined),
        liveness_threshold: get('estimate_liveness.liveness_threshold').asFloat().ifNaN(undefined),
        quality_threshold: get('estimate_liveness.quality_threshold').asFloat().ifNaN(undefined),
      },
      liveness_states: get('filters.liveness_states').asArrayOf(optionValue).ifEmpty(undefined),
      estimate_deepfake: {
        estimate: get('estimate_deepfake.estimate').asInt().ifNaN(undefined),
        real_threshold: get('estimate_deepfake.real_threshold').asFloat().ifNaN(undefined),
        mode: get('estimate_deepfake.mode').asOptionValue().ifNaN(undefined),
      },
      deepfake_states: get('filters.deepfake_states').asArrayOfOptionValues().ifEmpty(undefined),
      face_quality: {
        estimate: get('estimate.faceQuality').asInt().ifNaN(undefined),
        filter: get('filters.faceQuality').asInt().ifNaN(undefined),
        checks: {
          image_format: {
            estimate: get('faceQuality.imageFormat.isEnabled').asInt().ifNaN(undefined),
            threshold: get('faceQuality.imageFormat.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          illumination_quality: {
            estimate: get('faceQuality.illuminationQuality.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.illuminationQuality.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.illuminationQuality.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          specularity_quality: {
            estimate: get('faceQuality.specularityQuality.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.specularityQuality.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.specularityQuality.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          blurriness_quality: {
            estimate: get('faceQuality.blurrinessQuality.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.blurrinessQuality.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.blurrinessQuality.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          dark_quality: {
            estimate: get('faceQuality.darkQuality.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.darkQuality.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.darkQuality.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          light_quality: {
            estimate: get('faceQuality.lightQuality.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.lightQuality.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.lightQuality.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_yaw: {
            estimate: get('faceQuality.headYaw.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.headYaw.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headYaw.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_pitch: {
            estimate: get('faceQuality.headPitch.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.headPitch.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headPitch.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_roll: {
            estimate: get('faceQuality.headRoll.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.headRoll.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headRoll.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          gaze_yaw: {
            estimate: get('faceQuality.gazeYaw.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.gazeYaw.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.gazeYaw.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          gaze_pitch: {
            estimate: get('faceQuality.gazePitch.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.gazePitch.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.gazePitch.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          mouth_smiling: {
            estimate: get('faceQuality.mouthSmiling.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.mouthSmiling.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.mouthSmiling.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          mouth_occluded: {
            estimate: get('faceQuality.mouthOccluded.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.mouthOccluded.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.mouthOccluded.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          mouth_open: {
            estimate: get('faceQuality.mouthOpen.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.mouthOpen.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.mouthOpen.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          glasses: {
            estimate: get('faceQuality.glasses.isEnabled').asInt().ifNaN(undefined),
            threshold: get('faceQuality.glasses.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          left_eye: {
            estimate: get('faceQuality.leftEye.isEnabled').asInt().ifNaN(undefined),
            threshold: get('faceQuality.leftEye.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          right_eye: {
            estimate: get('faceQuality.rightEye.isEnabled').asInt().ifNaN(undefined),
            threshold: get('faceQuality.rightEye.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          head_horizontal_center: {
            estimate: get('faceQuality.headHorizontalCenter.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.headHorizontalCenter.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headHorizontalCenter.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_vertical_center: {
            estimate: get('faceQuality.headVerticalCenter.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.headVerticalCenter.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headVerticalCenter.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_width: {
            estimate: get('faceQuality.headWidth.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.headWidth.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headWidth.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_height: {
            estimate: get('faceQuality.headHeight.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.headHeight.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headHeight.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          eye_distance: {
            estimate: get('faceQuality.eyeDistance.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.eyeDistance.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.eyeDistance.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          image_width: {
            estimate: get('faceQuality.imageWidth.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.imageWidth.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.imageWidth.threshold.max').asInt().ifNaN(undefined),
            },
          },
          image_height: {
            estimate: get('faceQuality.imageHeight.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.imageHeight.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.imageHeight.threshold.max').asInt().ifNaN(undefined),
            },
          },
          aspect_ratio: {
            estimate: get('faceQuality.aspectRatio.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.aspectRatio.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.aspectRatio.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          face_width: {
            estimate: get('faceQuality.faceWidth.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.faceWidth.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.faceWidth.threshold.max').asInt().ifNaN(undefined),
            },
          },
          face_height: {
            estimate: get('faceQuality.faceHeight.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.faceHeight.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.faceHeight.threshold.max').asInt().ifNaN(undefined),
            },
          },
          indent_left: {
            estimate: get('faceQuality.indentLeft.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.indentLeft.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.indentLeft.threshold.max').asInt().ifNaN(undefined),
            },
          },
          indent_right: {
            estimate: get('faceQuality.indentRight.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.indentRight.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.indentRight.threshold.max').asInt().ifNaN(undefined),
            },
          },
          indent_upper: {
            estimate: get('faceQuality.indentUpper.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.indentUpper.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.indentUpper.threshold.max').asInt().ifNaN(undefined),
            },
          },
          indent_lower: {
            estimate: get('faceQuality.indentLower.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.indentLower.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.indentLower.threshold.max').asInt().ifNaN(undefined),
            },
          },
          image_size: {
            estimate: get('faceQuality.imageSize.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.imageSize.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.imageSize.threshold.max').asInt().ifNaN(undefined),
            },
          },
          eyebrows_state: {
            estimate: get('faceQuality.eyebrowsState.isEnabled').asInt().ifNaN(undefined),
            threshold: get('faceQuality.eyebrowsState.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          headwear_type: {
            estimate: get('faceQuality.headwearType.isEnabled').asInt().ifNaN(undefined),
            threshold: get('faceQuality.headwearType.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          smile_properties: {
            estimate: get('faceQuality.smileProperties.isEnabled').asInt().ifNaN(undefined),
            threshold: get('faceQuality.smileProperties.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          face_color_type: {
            estimate: get('faceQuality.faceColorType.isEnabled').asInt().ifNaN(undefined),
            threshold: get('faceQuality.faceColorType.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          natural_light: {
            estimate: get('faceQuality.naturalLight.isEnabled').asInt().ifNaN(undefined),
            threshold: get('faceQuality.naturalLight.threshold').as(optionValue),
          },
          red_eyes: {
            estimate: get('faceQuality.redEyes.isEnabled').asInt().ifNaN(undefined),
            threshold: get('faceQuality.redEyes.threshold').as(optionValue),
          },
          radial_distortion: {
            estimate: get('faceQuality.radialDistortion.isEnabled').asInt().ifNaN(undefined),
            threshold: get('faceQuality.radialDistortion.threshold').as(optionValue),
          },
          illumination_uniformity: {
            estimate: get('faceQuality.illuminationUniformity.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.illuminationUniformity.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.illuminationUniformity.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          dynamic_range: {
            estimate: get('faceQuality.dynamicRange.isEnabled').asInt().ifNaN(undefined),
            threshold: {
              min: get('faceQuality.dynamicRange.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.dynamicRange.threshold.max').asFloat().ifNaN(undefined),
            },
          },
        },
      },
    },
    extract_policy: {
      extract_basic_attributes: get('extract.basicAttributes').asInt().ifNaN(undefined),
      fd_score_threshold: get('filters.faceDescriptorScoreThreshold').asFloat().ifNaN(undefined),
    },
    storage_policy: {
      face_sample_policy: {
        store_sample: get('faceSamplePolicy.isEnabled').asInt().ifNaN(undefined),
      },
      attribute_policy: {
        store_attribute: get('attributesPolicy.isEnabled').asInt().ifNaN(undefined),
      },
    },
  },
});
