import { apiContainer } from '@vlabs/api-bindings';

export async function fetchFaces(pageSize, pageIndex, filters, listId) {
  const flatFilters = [];
  filters.forEach(({ id, value }) => {
    if (id === 'create_time') {
      flatFilters.create_time__gte = value?.[0] || undefined;
      flatFilters.create_time__lt = value?.[1] || undefined;
      // FIXME: непонятно что здесь происходит и почему в оригинале удаляется id
      // eslint-disable-next-line no-param-reassign
      delete filters.id;
    } else {
      flatFilters[id] = value;
    }
  });

  const { data, meta } = await apiContainer.lunaClient.faces.getAll(
    {
      list_id: listId,
      page: pageIndex + 1,
      page_size: pageSize,
      ...flatFilters,
    },
    { withCount: true },
  );

  return {
    data,
    facesCount: meta.count,
  };
}
