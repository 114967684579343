import React, { useEffect } from 'react';

import { FaceQualitySubForm, FiltersSubForm, DeepfakeSubForm, LivenessSubForm } from '@vlabs/pages/handlers/forms/index';
import { permissions } from '@vlabs/shared/config';
import { Page, Divider, Control, Grid, GridRow, GridCol, Fold } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { CommonSubForm, EstimationsSubForm, StoragePolicySubForm } from './forms/index';
import { selectVerifier } from './selectors';
import * as verifierApi from './store';

const VerifierFormPage = ({
  values,
  onSubmit,
  can,
}) => {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues: values });
  const { reset, handleSubmit } = formMethods;
  useEffect(() => { reset(values); }, [values, reset]);

  const onAction = (
    <Control.Button
      hasPermission={can(permissions.verifier.modification)}
      type="submit"
      variant="outlined"
    >
      {t('форма.submit')}
    </Control.Button>
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Page
          actions={onAction}
          title={t('verifiers:editForm.form title')}
        >
          <Divider />

          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Fold
                  isOpen
                  title={t('verifiers:editForm.subforms.titles.common')}
                >
                  <CommonSubForm />
                </Fold>
                <Divider />

                <Fold
                  isOpen
                  title={t('verifiers:editForm.subforms.titles.defined attributes')}
                >
                  <EstimationsSubForm />
                </Fold>
                <Divider />

                <LivenessSubForm />
                <Divider />

                <DeepfakeSubForm />
                <Divider />

                <FaceQualitySubForm />
              </GridCol>

              <GridCol cols={6}>
                <Fold
                  isOpen
                  title={t('verifiers:editForm.subforms.titles.save parameters')}
                >
                  <StoragePolicySubForm />
                </Fold>
                <Divider />

                <Fold
                  isOpen
                  title={t('verifiers:editForm.subforms.titles.filters')}
                >
                  <FiltersSubForm />
                </Fold>
              </GridCol>
            </GridRow>
          </Grid>
        </Page>
      </form>
    </FormProvider>
  );
};

VerifierFormPage.propTypes = {
  values: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
};
VerifierFormPage.defaultProps = {
  values: undefined,
};

export default connect((state) => ({
  values: selectVerifier(state),
  can: viewerCan(state),
}),
(dispatch, { match: { params: { verifierId } } }) => ({
  onSubmit: (data) => dispatch(verifierApi.updateVerifier(verifierId, data)),
}))(VerifierFormPage);
